import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import plugins from './plugins';
import {
  betslip, notifications, rules, user, translations, gamesBetslip,
} from './modules';

export default {
  state,
  getters,
  actions,
  mutations,
  plugins,
  modules: {
    betslip,
    notifications,
    rules,
    user,
    translations,
    gamesBetslip,
  },
};
