import axios from 'axios';
import { toUpper } from 'lodash';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 5000,
});

const httpPayin = (config) => {
  const platform = config.platformName ? config.platformName.toUpperCase() : 'SEVEN';
  const DC = toUpper(config.platformDataCenter);
  const path = DC ? `VUE_APP_${platform}_API_${DC}` : `VUE_APP_${platform}_API`;
  return axios.create({
    baseURL: process.env[path],
    timeout: 5000,
  });
};

const httpCancelTicket = (config) => {
  const platform = config.platformName ? config.platformName.toUpperCase() : 'SEVEN';
  const DC = toUpper(config.platformDataCenter);
  const path = DC ? `VUE_APP_${platform}_API_${DC}` : `VUE_APP_${platform}_API`;

  return axios.create({
    baseURL: process.env[path],
    timeout: 5000,
  });
};

const httpLastTickets = (config) => {
  const platform = config.platformName && config.platformName === 'playtech'
    ? config.platformName.toUpperCase()
    : 'NGS';
  const DC = toUpper(config.platformDataCenter);
  const path = DC ? `VUE_APP_${platform}_API_${DC}` : `VUE_APP_${platform}_API`;

  return axios.create({
    baseURL: process.env[path],
    timeout: 8000,
  });
};

const httpStreamMapping = axios.create({
  baseURL: process.env.VUE_APP_STREAM_MAPPING_API,
  timeout: 5000,
});

const httpTicketCheck = (config) => {
  const platform = config.platformName ? config.platformName.toUpperCase() : 'SEVEN';
  const DC = toUpper(config.platformDataCenter);
  const path = DC ? `VUE_APP_${platform}_API_${DC}` : `VUE_APP_${platform}_API`;

  return axios.create({
    baseURL: process.env[path],
    timeout: 5000,
  });
};

const httpStatistics = (config) => {
  const platform = config.platformName && config.platformName === 'playtech'
    ? config.platformName.toUpperCase()
    : 'NGS';
  const DC = toUpper(config.platformDataCenter);
  const path = DC ? `VUE_APP_${platform}_API_${DC}` : `VUE_APP_${platform}_API`;

  return axios.create({
    baseURL: process.env[path],
    timeout: 5000,
  });
};

const httpResults = (config) => {
  const platform = config.platformName && config.platformName === 'playtech'
    ? config.platformName.toUpperCase()
    : 'NGS';
  const DC = toUpper(config.platformDataCenter);
  const path = DC ? `VUE_APP_${platform}_API_${DC}` : `VUE_APP_${platform}_API`;
  return axios.create({
    baseURL: process.env[path],
    timeout: 5000,
  });
};

export {
  http,
  httpPayin,
  httpCancelTicket,
  httpLastTickets,
  httpTicketCheck,
  httpStreamMapping,
  httpStatistics,
  httpResults,
};
