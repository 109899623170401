const store = {
  // Game should override translations
  translations: null,
};

const types = {
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
};

export default {
  namespaced: false,
  state: store,
  actions: {
    setTranslations({ commit }, payload) {
      commit(types.SET_TRANSLATIONS, payload);
    },
  },
  getters: {
    translations: state => state.translations,
  },
  mutations: {
    [types.SET_TRANSLATIONS](state, translations) {
      state.translations = translations;
    },
  },
};
