const target = {
  blueoceangaming_staging: 'c26a4836-8c15-4120-b4f2-2e8ccab31684',
  bbin_staging: 'fae53cef-f120-4bef-9bc4-60c4563d1a5a',
  vipsportsbook: 'e7226993-5983-4860-85ae-47cfa984eb6a',
  slottyusd: '893d0f4e-e3d6-4997-8eac-afbd9a794ee0',
  slottyasia: 'a15c8b96-bd5f-4258-af86-2dc2c3086eab',
  betwayngn: '1d265779-fd85-4fad-8ef0-9311290b042e',
  exclusivebeteur: 'a48708ce-9394-43ae-b9a3-54c079e87a33',
};

export default {
  staging: {
    'a5c71a98-433f-4c52-ab4a-6e3cda9b18ee': target.blueoceangaming_staging,
    '869050d1-438c-43d2-92cc-64c3807d493b': target.bbin_staging,
    'efc77072-1af0-442f-aab1-9ee392fa530a': target.bbin_staging,
    '0e14a4ac-2a1f-4454-9786-221161117227': target.bbin_staging,
    '0af9cee2-56d8-4d0f-b709-38e1176597c6': target.bbin_staging,
    '883abfd2-9de9-47ef-a621-60e19fce2fad': target.bbin_staging,
    '7801ec3a-7a20-4881-8253-acde4f85af83': target.bbin_staging,
    '27768f55-1952-4d86-9b91-b22e3daed47d': target.bbin_staging,
  },
  production: {
    '080d24af-6624-4891-b476-205478fe6f04': target.vipsportsbook,
    'dbb1e714-d442-4e54-9574-74560d388ff3': target.vipsportsbook,
    'd8ac7387-4ac2-4572-90a2-010917dc2236': target.vipsportsbook,
    '59a291bc-4641-4953-a0c7-a4f3071ce846': target.vipsportsbook,
    'c91074c3-29df-4eef-aea4-70ffb8ccdb8f': target.vipsportsbook,
    '2b873053-c439-485f-b9da-dac6ebdb2026': target.vipsportsbook,
    '764ac1f5-8597-45ab-b1df-04da7003989a': target.slottyusd,
    'bd82a534-3f5b-47d7-b3db-820b0d50e34e': target.slottyusd,
    '06959119-679c-4156-bdd4-f6ac78182d01': target.slottyusd,
    '968a752a-ea5c-4e6b-a2a7-cdba0e3f6ea7': target.slottyusd,
    '24d0c5b6-ec46-4465-a8fc-b1500c5ad646': target.slottyusd,
    'b1933394-e565-4657-b5cb-93e1516e03c7': target.slottyusd,
    'c74b6cdf-9b23-438b-9d21-610a217d8854': target.slottyusd,
    '189d76b5-37fc-4492-88ce-728f2a34665b': target.slottyusd,
    '7ad4a286-c051-442d-a6c4-31fb26f714a2': target.slottyusd,
    '3cd2546d-1a06-4931-8c23-82ffed24b05d': target.slottyusd,
    '92fe19dc-8990-4d7c-9423-ad24cc74fe3f': target.slottyusd,
    '5078036d-67be-4518-a9e8-f34867376ae4': target.slottyusd,
    '16283201-d49b-4425-a848-4846ea0df8c4': target.slottyusd,
    'e9cae8d4-3fe3-45db-9977-16f321346ba9': target.slottyusd,
    'b362384c-7615-4c14-a8c2-927de2484e96': target.slottyusd,
    '2e5eddca-db18-4181-8d23-fe26fd49282c': target.slottyusd,
    '2b63ff74-0f75-431e-bccf-e9991655c5e7': target.slottyusd,
    '09530f56-af25-4e6d-87e7-53da6693becf': target.slottyusd,
    '46c817b7-0c17-400a-ae36-8f7202ff8532': target.slottyusd,
    '65c8c1f7-91b2-41c9-94a3-6815261f3bc6': target.slottyusd,
    '87225c93-6cd8-403c-b2ef-ca7edee5161b': target.slottyusd,
    'fc5c5b9d-d19c-404d-a97b-3157e8aeaf26': target.slottyusd,
    '1298ce31-aa09-4846-9df4-99d42dc6ea06': target.slottyusd,
    '893d0f4e-e3d6-4997-8eac-afbd9a794ee0': target.slottyusd,
    '3a756097-0ae5-44f9-a69d-6ae85f00dda1': target.slottyusd,
    '93529a71-619c-4882-84b8-3f0be67f02ae': target.slottyusd,
    'eb36d33b-0c0c-4f1f-b52f-12ea13c5bc89': target.slottyusd,
    'd41a665e-1565-4891-8042-c6b50b32aac7': target.slottyusd,
    'fb795cc5-ef17-449a-840e-fa370194e34c': target.slottyusd,
    '38f18902-2f3e-47ee-a624-2a211613cfc6': target.slottyasia,
    '8a738758-ba7b-4d02-97d2-c904412c50e6': target.slottyasia,
    '2c675386-e92c-4bc7-b707-b07ff770d25e': target.slottyasia,
    'f6c727c4-3566-49c2-ab86-e9a48713f6b1': target.slottyasia,
    'e882c306-c619-4a0a-ba23-6934c3cc207f': target.slottyasia,
    '21711c60-24f4-4cc9-aeac-0504cb5b4f35': target.slottyasia,
    '3036cd7a-710e-4e97-8e23-45bf8024cda5': target.slottyasia,
    '272e7eb8-f14d-4260-8023-f2f7419b45d1': target.slottyasia,
    'fcd5c8eb-3086-4c57-ba9a-4e12427cdf87': target.slottyasia,
    '393f4b4f-9d70-4e69-9146-b751923b4eac': target.slottyasia,
    '22dfa41b-694c-4286-ad6e-4fe9a476281b': target.slottyasia,
    '3a0e6b9a-e9b8-4773-9a5c-bdb1a6a45bc3': target.slottyasia,
    '28a8827e-5294-4276-a32c-a0d22492e059': target.slottyasia,
    '38572b8b-e840-46b5-b205-a87bed48e41c': target.slottyasia,
    'e381ba14-4b4e-4b6e-80ba-a3fe26a42b43': target.slottyasia,
    '86541e2b-5a79-4590-8ef0-1dbd7d1b9f61': target.slottyasia,
    'a37be64b-6d11-422a-b413-dbb4a7efa180': target.slottyasia,
    'a5c45d4e-cfcd-45a5-9e68-c7bec4080da4': target.slottyasia,
    'bbc35736-0d5b-49d1-bb83-bde9d2e04418': target.slottyasia,
    '0482b49a-cea8-487f-b6c6-e70842b227aa': target.slottyasia,
    'e5cb44e5-c8f8-4c71-9de1-b3288eb66309': target.slottyasia,
    '79b13b38-4665-443b-8247-5aaabec1efe5': target.slottyasia,
    'c07255a3-76f7-4870-a3c5-9585a03d08be': target.slottyasia,
    '850eae8c-d26d-4695-a053-ec99152b3c88': target.slottyasia,
    'acbe3d84-ecf3-4436-932a-206b1913d7cf': target.slottyasia,
    '9486cf04-7ea0-4f10-bad5-ab4e76a2626c': target.slottyasia,
    '78323f7a-da7e-40ec-89c2-6ea1849c502f': target.slottyasia,
    '2f6f45e3-3fc4-47fe-84ac-4e1c3aec321d': target.slottyasia,
    'e2543a88-d927-47f8-be1e-87bef1bb7e48': target.slottyasia,
    '7827e4a6-a9a7-49cb-a244-c1b5c05e27e6': target.slottyasia,
    'd87e4f26-73e5-4782-a1e0-bc1c46d43116': target.slottyasia,
    '9e324c4e-6082-4f11-9084-56477f5d2b26': target.slottyasia,
    '8858760c-0049-410c-a0d1-aad9c2520c98': target.betwayngn,
    '1d265779-fd85-4fad-8ef0-9311290b042e': target.betwayngn,
    '255c4106-d706-418f-aab1-038153d0450c': target.betwayngn,
    '1aa92058-a61f-4f03-aa8a-10096e43a7cc': target.betwayngn,
    '8cbb9137-3ead-48d4-9b4f-0def38ea7df3': target.betwayngn,
    'e8a4dafb-8221-4362-9373-ff4bcb02430d': target.vipsportsbook,
    'dfea88d3-5640-4818-ae7a-9cf1fd4a1b5d': target.slottyusd,
    '6d709234-deba-456e-ac3a-bdd4493924db': target.exclusivebeteur,
    '8a9bca26-6052-4627-b82b-ce0657e3d321': target.exclusivebeteur,
    '3f4c03fe-53c5-4ec0-9163-032d54be5041': target.exclusivebeteur,
    'c92daada-0bb1-4a3c-bd2b-d8053a106e54': target.exclusivebeteur,
    'e2156676-50ea-4a35-8b12-651e58e4a0ad': target.exclusivebeteur,
    '90644590-9aaa-4e43-8e4b-5629c5540d3d': target.exclusivebeteur,
    'beb5a34f-00c5-4c48-9d60-34a14c2b989d': target.exclusivebeteur,
    'f9be7d28-c606-49bc-8fdd-4e2d9a9919ef': target.exclusivebeteur,
    '692225e3-1b4a-47dd-870d-cd53e7db7c16': target.exclusivebeteur,
    'ef9beeb2-1314-4b77-b725-0d1b2fc4c37e': target.exclusivebeteur,
    '0ac47c44-a608-4e83-99ba-2f90988b163f': target.exclusivebeteur,
    'f9910bc6-6cdb-4c55-b81c-2a089ed0fcc8': target.exclusivebeteur,
    'c21eff9f-4c0d-4d02-abc5-2c0a4fafcb53': target.exclusivebeteur,
    'c7337d76-39d0-4a55-afab-d110b7c741ea': target.exclusivebeteur,
    '9af1232e-e1ee-4727-9b16-dde32075c831': target.exclusivebeteur,
    '991ae7ea-89a4-4a82-828c-5d420b07d34f': target.exclusivebeteur,
    'bf8ad09c-363e-4297-bd14-d24b12c8d9c5': target.exclusivebeteur,
    'ab09a24b-1e30-459b-a427-3c31e9b44196': target.exclusivebeteur,
    'f5009d77-07b3-4c1f-a725-a703bbf45263': target.exclusivebeteur,
    '2b92cca1-ffe0-46e3-b39a-5679e4f0bd03': target.exclusivebeteur,
    '41d4f30d-564b-4815-ac01-6e4490a49e96': target.exclusivebeteur,
    '9c2d3830-e24c-4900-80fd-4df512db97fc': target.exclusivebeteur,
    '35dfe77b-6d86-4ba7-b745-cea033fe95c3': target.exclusivebeteur,
    '62e84d6f-dfa6-4c40-b622-037e8e81450d': target.exclusivebeteur,
    '920b0014-ba76-4a56-826a-3e92dff8b510': target.exclusivebeteur,
    'ade2867b-5237-4f7e-b1d6-ebc33388c5df': target.exclusivebeteur,
    '381252cf-792c-4cfd-8f25-a49492b09f3f': target.exclusivebeteur,
    '663c800c-0502-4d5b-9ddf-77791a64861a': target.exclusivebeteur,
    '16703ef2-4e3a-4d8c-9298-190677c755ae': target.exclusivebeteur,
    'd5f51414-81fc-4402-99f5-7cfd27276905': target.exclusivebeteur,
    '7cf66008-107a-4142-9eb5-5adf475afad6': target.exclusivebeteur,
    'd8222691-04f9-49f6-9e16-4400ec71bde6': target.exclusivebeteur,
    '510e64d6-3db9-4311-bf84-cfcc5bc02bb9': target.exclusivebeteur,
    '4b4293d2-176c-4915-8be9-574867c12b09': target.exclusivebeteur,
    '4da602c3-ea88-493f-95ee-1350bc65fa4f': target.exclusivebeteur,
    '257d80e8-db7d-4e4a-ac3f-37ac23ac04d2': target.exclusivebeteur,
    'f78b3bc7-5d06-44a6-b362-47986e760385': target.exclusivebeteur,
    'd58aef49-4aae-473a-99e6-8f63abd0a86c': target.exclusivebeteur,
    '5ac54a86-1b68-4e6d-aba7-55ec2795d7c9': target.exclusivebeteur,
    '0bfea55a-cef5-4829-a8b9-7e1f645213f0': target.exclusivebeteur,
    'c6be6e3f-f71f-49bb-932a-69f18b2a37ed': target.exclusivebeteur,
    'a349cd80-afb9-4e83-83d7-b0c2322473d4': target.exclusivebeteur,
    '0458f676-444d-4e47-93cd-d21a10a15358': target.exclusivebeteur,
    '5450bbfe-f75e-479a-9a66-f9cbc9f04572': target.exclusivebeteur,
    'b5012f33-6d94-4044-8d9e-b4ab9c5ed32a': target.exclusivebeteur,
    '3d2fb61a-935d-40e0-8cc8-d3b86c5b7d93': target.exclusivebeteur,
    '9b6e853d-9c41-49b7-acd4-99665b8d8656': target.exclusivebeteur,
    'd7c6dfbe-4a8d-4a98-807b-005ba74f66ab': target.exclusivebeteur,
    'a4b778c9-7fc6-4b77-8c52-2a5a5257cbfb': target.exclusivebeteur,
    '5b488e13-2850-4a37-a5e5-a15bd0e419b3': target.exclusivebeteur,
    'e535e538-b446-4b0e-ac89-28b6ae126418': target.exclusivebeteur,
    'adc34488-b309-4049-891c-b86b99998219': target.exclusivebeteur,
    '343392f7-f06f-41da-904d-fbab1daef669': target.exclusivebeteur,
    '7bec5a55-ba93-44c7-9624-9e5ea1b16de8': target.exclusivebeteur,
    '920aaac1-c9a4-457a-995c-2ac10f627a43': target.exclusivebeteur,
    'cd2572a4-64a7-40c3-bbb1-bae293d2976e': target.exclusivebeteur,
    '32cf51cf-1ef4-4402-a6b5-01e6819e0f40': target.exclusivebeteur,
    '34270baa-f8fd-4a51-bd86-cf4ba63be7e9': target.exclusivebeteur,
    '79eabe99-8e05-4d6c-a2f0-5a9184b7f46f': target.exclusivebeteur,
    'c29efc7e-e0fd-4811-a955-c083b1316f1d': target.exclusivebeteur,
    '5a721701-1b41-4c30-984f-2c95a473ffec': target.exclusivebeteur,
  },
};
