export default {
  config: { // todo
    futureBets: 10,
    taxes: {
      payin: {
        policy: 'BAPayinTax', value: true, hideTax: true,
      },
      payout: {
        policy: 'BAPayoutTax', value: true, hideTax: true,
      },
    },
    ticketCancelAllowed: false, // TODO check naming
    ticketRebetAllowed: true,
    possibleWinPerBet: false,
    paymentPerBet: false,
    futurePerBet: false,
  },
  future: 1,
  isBetslipOpened: false,
  isBetslipLocked: false,
  isPaymentModified: false,
  isPayinBtnDisabled: false,
  isFreeBetButtonDisabled: false,
  isQuickpayEnabled: false,
  isPaymentValid: true,
  payment: 1,
  paymentPerBet: null,
  resetStakeAfterBetRemove: true,
  totalPayment: 0,
  tickets: {
    1: [],
    2: [],
    3: [],
  },
  ticketType: {
    type: 3,
    name: 'Single',
    value: 'single',
  },
  ticketsHistory: [],
  playerTickets: [],
  selectedBet: {
    value: {},
    valid: false,
  },
  editBet: null,
  lastTicketsInProgress: false,
  ticketPayinInProgress: false,
  betslipComponents: [],
  betslipActiveComponent: {},
  ticketTypeActiveComponent: {},
  ticketTypeComponents: [],
  betslipBlockers: [],
  mobileVisualizationHeight: 352,
  betslipFooterHeight: 87,
  isBetValid: false,
  isScheduleDisabled: false,
};
