import axios from 'axios';
import gamesHeaders from './headers';

const http = axios.create({
  baseURL: process.env.VUE_APP_NGS_API,
  timeout: 5000,
});

export default {
  async getBonuses(config, user) {
    const headers = {
      ...gamesHeaders.getAuthHeader(user, config),
      ...gamesHeaders.getNgsHeaders(config, user),
    };
    const { data } = await http.get('/api_open/bonus/luckyloser', {
      params: {
        product: config.productName,
      },
      headers,
    });

    return data;
  },
};
