import { Money } from '@nsftx/money-js/dist/bundle.umd';

export default {
  /**
  * Create Money instance with main unit
  * Returns amount converted to main unit value
  *
  * @param {number} amount
  * @param {string} currency
  */
  getMainUnit(value, currency = 'EUR') {
    const money = Money.createFromMainUnit(value, currency);
    return money.getAmountAsMainUnit();
  },
};
