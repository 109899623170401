import { httpStreamMapping } from './http';

export default {
  getStreamUuid(env, tenantId) {
    const url = process.env.VUE_APP_STREAM_MAPPING_PATH;
    const params = {
      env,
      tenantId,
    };

    return httpStreamMapping.get(url, { params });
  },
};
