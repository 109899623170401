export default {
  hexToRgba(color, opacity) {
    let r;
    let g;
    let b;
    const a = opacity / 100;
    const hex = color.substring(1);
    if (hex.length === 6) {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else {
      const rd = hex.substring(0, 1) + hex.substring(0, 1);
      const gd = hex.substring(1, 2) + hex.substring(1, 2);
      const bd = hex.substring(2, 3) + hex.substring(2, 3);
      r = parseInt(rd, 16);
      g = parseInt(gd, 16);
      b = parseInt(bd, 16);
    }
    return `rgba(${r},${g},${b},${a})`;
  },
  RGBToHSL(red, green, blue) {
    const r = red / 255;
    const g = green / 255;
    const b = blue / 255;

    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;

    let h = 0;
    let s = 0;
    let l = 0;

    if (delta === 0) {
      h = 0;
    } else if (max === r) {
      h = ((g - b) / delta) % 6;
    } else if (max === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
      h += 360;
    }

    l = (min + max) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = Number((s * 100).toFixed(1));
    l = Number((l * 100).toFixed(1));

    return [h, s, l];
  },
};
