import { isUndefined } from 'lodash';

if (!Math.factorial) {
  Math.factorial = function factorial(n) {
    if (n === 0 || n === 1) {
      return 1;
    }

    if (Math.factorial.f[n] > 0) {
      return Math.factorial.f[n];
    }

    Math.factorial.f[n] = factorial(n - 1) * n;
    return Math.factorial.f[n];
  };
  Math.factorial.f = [];
}

if (!String.prototype.supplant) {
  // eslint-disable-next-line
  String.prototype.supplant = function (o) {
    return this.replace(
      /{([^{}]*)}/g,
      (a, b) => {
        const r = o[b];
        return typeof r === 'string' || typeof r === 'number' ? r : a;
      },
    );
  };
}

export default {
  isValidColor(color) {
    const s = new Option().style;
    s.color = color;
    return s.color !== '' ? s.color : false;
  },
  getCombinations(n, k) {
    return Math.factorial(n) / (Math.factorial(k) * Math.factorial(n - k));
  },
  getNumbers(min, max, len) {
    const minNum = isUndefined(min) ? 1 : min;
    const maxNum = isUndefined(max) ? 10 : max;
    const length = isUndefined(len) ? 10 : len;

    let num;
    const nums = [];

    if (length > 0) {
      while (nums.length < length) {
        num = this.getRandomInt(minNum, maxNum);
        if (nums.indexOf(num) < 0) {
          nums.push(num);
        }
      }
    }

    return nums;
  },
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
};
