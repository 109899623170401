<template>
  <div class="ticket-details-wrapper">
    <div class="header">
      <div class="ticket-info">
        <h1 class="heading">
          {{ ticketInfo.productName }}
        </h1>
        <span class="separator">|</span>
        <h1 class="status" :class="`id-${ticketInfo.status.id}`">
          {{ ticketStatus }}
        </h1>
      </div>
      <div class="ticket-meta-info">
        <span class="ticket-id">
          {{ translations.general_ticket_id }}: {{ ticketInfo.id }}
        </span>
        <span class="separator">|</span>
        <span class="date-time">{{ ticketInfo.ticketDateTimeUTC }}</span>
      </div>
    </div>
    <div class="body">
      <div class="headings">
        <span
          class="heading"
          v-for="(heading, index) in headers"
          :key="index">{{ translations[heading.value] }}</span>
        <span class="heading" />
      </div>
      <div class="bets">
        <div v-for="(bet, index) in bets" :key="index" class="bet">
          <span class="bet-item" v-for="(item, index) in bet.rowData" :key="item.key">
            <span v-if="index === 2">
              <div class="test" v-html="checkValueTranslation(item.value)"></div>
            </span>
            <span v-else>{{ checkIfCrash(item.value) }}</span>
          </span>
          <span class="bet-item">
            <i
              class="icon status"
              :class="[getStatusIcon(bet.id), `id-${bet.id}`]"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="payin-info-wrapper">
        <div class="item-wrapper" v-for="item in payinInfo" :key="item.key">
            <div class="item">{{ translations[item.key] }}</div>
            <span class="item" />
          <span class="item-amount">{{ item.value.toFixed(2) }}</span>
        </div>
      </div>
      <div v-if="showMaxWinningsInfo" class="possible-win-wrapper">
        <div class="info-item" v-for="item in maxWinningsInfo" :key="item.key">
          <span class="possible-win">{{ translations[item.key] }}</span>
          <span class="possible-win-amount">
            {{ item.value.toFixed(2) }}
          </span>
        </div>
      </div>
      <div v-if="bonus" class="jackpot">
        <div class="icon icon-ajs"></div>
        <div class="separator">|</div>
        <span class="bonus">{{ bonus.name }} +{{ bonus.amount }}</span>
      </div>
      <div class="actions-wrapper">
        <button
            v-if="isCancelTicketEnabled"
            class="button left"
            @click="cancel">
          {{ translations.general_cancel }}</button>
        <button
          v-if="payoutEnabled"
          class="button right"
          @click="payout">
          {{ translations.general_payout}}</button>
        <button v-if="rebetEnabled" class="button right" @click="rebet">
          {{ translations.general_rebet }}</button>
          <button class="button right" v-if="closeEnabled" @click="close">
          {{ translations.general_close }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TerminalTicketDetails',
  data() {
    return {
      isLoaded: false,
    };
  },
  emits: ['rebet', 'cancel', 'payout'],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    cancelEnabled: {
      type: Boolean,
      default: true,
    },
    rebetEnabled: {
      type: Boolean,
      default: true,
    },
    closeEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['translations']),
    ticketInfo() {
      return this.ticket.ticketInfo;
    },
    headers() {
      return this.ticket.headers;
    },
    bets() {
      return this.ticket.bets;
    },
    payinInfo() {
      return this.ticket.payinInfo;
    },
    maxWinningsInfo() {
      return this.ticket.maxWinningsInfo;
    },
    showMaxWinningsInfo() {
      return this.maxWinningsInfo.length > 0;
    },
    bonus() {
      return this.ticket.bonus;
    },
    isCancelTicketEnabled() {
      return this.cancelEnabled && this.ticketInfo.status.id === '0';
    },
    ticketStatus() {
      return this.translations[`general_ticket_status_${this.ticketInfo.status.value.toLowerCase()}`].toUpperCase();
    },
    payoutEnabled() {
      const { id } = this.ticketInfo.status;
      return !this.isPaidout(id) && !this.isExpired(id) && (this.isWon(id) || this.bonus);
    },
  },
  methods: {
    getStatusIcon(id) {
      if (id === '0' || id === '8') return 'icon-open';
      if (id === '2' || id === '5') return 'icon-won';

      return 'icon-lost';
    },
    isWon(id) {
      return id === '2';
    },
    isPaidout(id) {
      return id === '5';
    },
    isExpired(id) {
      return id === '13';
    },
    payout() {
      this.$emit('payout');
    },
    cancel() {
      this.$emit('cancel');
    },
    rebet() {
      this.$emit('rebet');
    },
    close() {
      this.$emit('close');
    },
    // This will be removed once crash cash translations are sent by backend
    checkIfCrash(key) {
      return key === 'crash_cash_market' ? this.translations.crash_cash_market : key;
    },
    checkValueTranslation(value) {
      return this.translations[value] ?? value;
    },
  },
};
</script>

<style lang="scss" scoped>

.ticket-details-wrapper {
  padding: 2.7rem 1.7rem;
  background-color: var(--card);
  height: 100vh;
  overflow: auto;
  .header {
    .ticket-info {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;

      h1 {
        margin: 0;
      }

      .heading {
        font-size: inherit;
        color: var(--text-primary-1);
      }

      .separator {
        color: var(--text-primary-1);
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .status {
        font-size: inherit;

        &.id-1, &.id--1, &.id-13 {
          color: var(--system-negative);
        }

        &.id-2, &.id-5 {
          color: var(--system-positive);
        }

        &.id-0, &.id-8 {
          color: var(--system-neutral);
        }

        &.id-4 {
          color: var(--system-negative);
        }
      }
    }

    .ticket-meta-info {
      margin-top: 0.6rem;
      display: flex;
      align-items: center;
      color: var(--text-primary-2);
      font-size: 1rem;

      .separator {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  .body {
    margin-top: 1.3rem;

    .headings {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      color: var(--text-primary-2);
      text-transform: uppercase;
      padding: 0 1.6rem;

      .heading {
        flex: 1;
        text-align: center;
      }
      .heading:nth-of-type(3) {
        flex: 2.5;
      }
      .heading:nth-of-type(2) {
        flex: 2;
      }
      .heading:nth-of-type(1) {
        text-align: left;
      }

    }

    .bets {
      margin-top: 0.5rem;

      .bet {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        color: var(--text-primary-1);
        background-color: var(--card-section);
        height: fit-content;
        padding: 0.5rem 1.6rem;
        border-radius: 0.2rem;
        margin-bottom: 0.2rem;

        .bet-item {
          flex: 1;
          text-align: center;
          .icon {
            &.id-2, &.id-5 {
              color: var(--system-positive);
            }

            &.id-0, &.id-8 {
              color: var(--system-neutral);
            }

            &.id-1, &.id-4, &.id--1 {
              color: var(--system-negative);
            }
          }
        }
        .bet-item:nth-of-type(3) {
          flex: 2.5;
        }
        .bet-item:nth-of-type(2) {
          flex: 2;
        }
        .bet-item:nth-of-type(1) {
          color: var(--text-primary-3);
          text-align: left;
          margin: 0.5rem 0;
        }
        .bet-item:last-child {
          text-align: right;
        }
      }
    }
  }

  .footer {
    margin-top: 0.9rem;
    display: flex;
    flex-direction: column;

    .payin-info-wrapper {
      background-color: var(--card-section);
      color: var(--text-primary-2);
      font-size: 1rem;
      border-radius: 0.2rem;
      height: fit-content;
      padding: 0.5rem 1.6rem;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .item-wrapper {
        display: flex;
        margin: 2px 0;

        .item-amount {
          margin-left: auto;
        }
      }
    }

    .possible-win-wrapper {
      background-color: var(--card-section);
      color: var(--text-primary-1);
      border-radius: 0.2rem;
      margin-top: 0.2rem;
      display: flex;
      flex-direction: column;
      height: fit-content;
      font-size: 1.2rem;
      padding: 0.5rem 1.6rem;
      .info-item {
        display: flex;
        justify-content: space-between;
        .possible-win-amount {
          margin-left: auto;
        }
      }
    }

    .jackpot {
      display: flex;
      align-items: center;
      background-color: var(--system-neutral);
      font-size: 1.1rem;
      padding: 1.2rem 1rem;
      color: var(--background-1);
      font-weight: 500;
      border-radius: 2px;
      .icon-ajs {
        font-size: 1.3rem
      }
      .separator {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        display: flex;
      }
    }

    .actions-wrapper {
      margin-top: 2.5rem;
      text-align: right;
      .button {
        border: 0;
        background-color: transparent;
        color: var(--secondary);
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
      }
      .button:first-child {
        padding-left: 1.6rem;
      }
      .button:last-child {
        padding-right: 1.6rem;
      }
      .left {
        float: left;
      }
      .right {
        margin-left: 6rem;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: var(--card);
}
::-webkit-scrollbar-thumb {
  background: var(--button);
  border-radius: 3px;
}
</style>
