/* eslint-disable implicit-arrow-linebreak */

const DOT = '.';
const COMMA = ',';
const ZERO = '0';

export default {
  ALLOWED_CHARACTERS: /[,.\d]/,
  ALLOWED_KEYS: ['Backspace', 'ArrowLeft', 'ArrowRight', 'Shift', 'Delete', 'Tab', 'Enter'],
  ALLOWED_KEY_CODE: 46,
  isDotLimitReached: (value, key) =>
    (key === DOT || key === COMMA) && value.includes(DOT),
  isDecimalLimitReached: value => value.split('.')?.[1]?.length >= 2,
  replaceCommaWithDot: value => value.replace(COMMA, DOT),
  checkIsCursorBeforeDecimalPoint: (cursorPosition, value) =>
    cursorPosition <= value.indexOf(DOT),
  checkIsCursorBeforeFirstChar: (cursorPosition, value) =>
    cursorPosition === value.indexOf(value[0]),
  checkIsCursorBeforeZero: (cursorPosition, value) =>
    cursorPosition <= value.indexOf(ZERO),
  trimDecimalsOverLimit: (value) => {
    const [before, after] = value.split('.');
    return `${before}.${after.slice(0, 2)}`;
  },
  isFirstCharZero: value => value.startsWith(ZERO),
  isSecondCharDot: value => value[1] === DOT,
  valueIncludesDot: value => value.includes(DOT),
  valueIncludesComma: value => value.includes(COMMA),
  isKeyDifferentFromDotAndComma: key => key !== DOT && key !== COMMA,
  isFirstCharDot: value => value[0] === DOT,
  removeCommas: value => value.replaceAll(COMMA, ''),
  isKeyZero: key => key === ZERO,
  isNumberLimitReached(value) {
    if (this.valueIncludesDot(value)) {
      const [before] = value.split('.');
      return before.length >= 13;
    }

    return value.length >= 13;
  },
  isValueSelected: value =>
    document.getSelection().toString() !== '' && value === document.getSelection().toString(),
  trimValueToNumberLimit: value => value.slice(0, 13),
  dispatchKeyDownEvent: (element, key) => element.dispatchEvent(new KeyboardEvent('keydown', { key })),
  dispatchKeyUpEvent: (element, key) => element.dispatchEvent(new KeyboardEvent('keyup', { key })),
  insertAtCursorPosition: (value, key, cursorPosition) => {
    const before = value.slice(0, cursorPosition);
    const after = value.slice(cursorPosition);

    return `${before}${key}${after}`;
  },
};
