/**
*
* Custom made object representing current app instance
* Primary used to for support with transition to vue 3
*
*/
const currentApp = {
  app: require('vue'),
  getVersion() {
    return this.app.version;
  },
  isVue2() {
    return this.app.version[0] === '2';
  },
  isVue3() {
    return this.app.version[0] === '3';
  },
};
export default currentApp;
