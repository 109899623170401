import types from './mutationTypes';
import setValue from '../../../utility/setValue';

export default {
  [types.CLEAR_NOTIFICATION](state) {
    state.notification = {
      type: '',
      value: '',
      status: '',
    };
  },
  [types.SET_NOTIFICATION](state, payload) {
    setValue(state.notification, 'value', payload.value);
    setValue(state.notification, 'status', payload.status || 'negative');

    if (payload.type) {
      setValue(state.notification, 'type', payload.type);
    }
  },
  [types.SET_CALLBACK](state, callback) {
    state.callback = callback;
  },
};
