<template>
  <div
    v-if="isPossibleWinActive"
    class="possibleWinWrapper"
    :class="{ isA: isA }"
  >
    <div v-if="isA">
      <div>
        <div class="possibleWin">
          <div>
            {{ translations.general_possible_winning }}
          </div>
          <div>
            {{ decimalFormatNumber(possibleWin.possibleWinnings) }}
          </div>
        </div>
        <div class="payIn" v-if="isPayinTaxActive">
          <div>
            {{ translations.general_payin_tax }}
          </div>
          <div>
            {{ decimalFormatNumber(possibleWin.possiblePayinTax) }}
          </div>
        </div>
        <div class="payOut" v-if="isPayoutTaxActive">
          <div>
            {{ translations.general_payout_tax }}
          </div>
          <div>
            {{ decimalFormatNumber(possibleWin.possiblePayoutTax) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="possibleWin">
        <div>
         {{ translations.general_possible_winning }}
        </div>
        <div>
          {{ decimalFormatNumber(possibleWin.possibleWinnings) }}
        </div>
      </div>
      <div class="payIn" v-if="isPayinTaxActive">
        <div>
          {{ translations.general_payin_tax }}
        </div>
        <div>
          {{ decimalFormatNumber(possibleWin.possiblePayinTax) }}
        </div>
      </div>
      <div class="payOut" v-if="isPayoutTaxActive">
        <div>
          {{ translations.general_payout_tax }}
        </div>
        <div>
          {{ decimalFormatNumber(possibleWin.possiblePayoutTax) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PossibleWin',
  props: {
    isA: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['config', 'possibleWin', 'taxes', 'decimalFormatNumber']),
    ...mapGetters(['translations']),
    isPossibleWinActive() {
      return this.config.isPossibleWinActive;
    },
    isPayinTaxActive() {
      return !this.taxes.payin.hideTax;
    },
    isPayoutTaxActive() {
      return !this.taxes.payout.hideTax;
    },
  },
  emits: ['updateFooterHeight'],
  methods: {
    ...mapActions('gamesBetslip', ['calculatePossibleWin']),
    toggleClick() {
      this.isExpanded = !this.isExpanded;
      this.$emit('updateFooterHeight');
    },
  },
  mounted() {
    this.calculatePossibleWin();
  },
};
</script>

<style lang="scss" scoped>
.possibleWinWrapper {
  &.isA {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  position: relative;
  clear: both;

  .possibleWin {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: var(--text-primary-1);
  }

  .payIn, .payOut {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--text-primary-1);
    opacity: 0.6;
    margin-top: 2px;
  }
}
</style>
