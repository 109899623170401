<template>
  <div
    class="betslipB"
    :class="{
      betslipBMobile: isMobile,
      wideScreenLayout: isWideScreenLayout,
      zIndex: isZIndexActive && !isMobile
    }"
    :style="styles"
  >
    <div  class="betslipA">
      <Transition name="slide">
        <div v-if="isBetslipOpen">
          <BetslipA :isA="false" :bgImage="bgImage" :isBallDrawn="isBallDrawn" />
        </div>
      </Transition>
    </div>
    <div class="overlay-wrapper">
      <BetslipInfoBar class="infoBar" />
      <BetslipFooter @change="handleChange" class="footer" />
      <DisableOverlay v-if="isPayinInProgress" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BetslipA from '../BetslipA/BetslipA';
import BetslipFooter from './BetslipFooter';
import BetslipInfoBar from './BetslipInfoBar';
import DisableOverlay from '../../DisableOverlay';

export default {
  name: 'BetslipB',
  components: {
    BetslipA,
    BetslipFooter,
    BetslipInfoBar,
    DisableOverlay,
  },
  props: {
    bgImage: {
      type: String,
      default: null,
    },
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      isBetslipOpen: false,
      isZIndexActive: false,
      isZIndexActiveTimeoutId: null,
    };
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketTypes', 'isPayinInProgress', 'roundNumber', 'rules', 'ticket']),
    ...mapGetters(['isMobile']),
    styles() {
      if (this.isMobile) {
        return {
          left: '0',
          bottom: '0',
        };
      }

      return {
        bottom: '0',
      };
    },
    isWideScreenLayout() {
      return !this.isMobile;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['setIsBetAppearAnimationDisabled', 'setTotalPaymentValue', 'setActiveTicketType']),
    handleChange(isOpen) {
      this.isBetslipOpen = isOpen;

      if (isOpen) {
        if (this.ticket.length) {
          this.setIsBetAppearAnimationDisabled(true);
        }

        this.isZIndexActive = true;
      } else {
        this.isZIndexActive = false;
      }
    },
  },
  beforeMount() {
    this.setActiveTicketType(this.activeTicketTypes[0]);
  },
  mounted() {
    this.setTotalPaymentValue(this.roundNumber(this.rules.minBetAmount.value));
  },
  beforeUnmount() {
    clearTimeout(this.isZIndexActiveTimeoutId);
  },
  beforeDestroy() {
    clearTimeout(this.isZIndexActiveTimeoutId);
  },
};
</script>

<style lang="scss" scoped>
.betslipB {
  position: fixed;
  min-width: 360px;
  max-width: 848px;
  width: 100%;

  .overlay-wrapper {
    position: relative;
  }

  &.zIndex {
    .betslipA {
      z-index: 20;
    }

    .overlay-wrapper {
      .footer {
        z-index: 25;
      }
    }
  }

  .betslipA {
    position: relative;

    ::v-deep .betslip {
      min-width: 360px;
      width: 100%;
    }
  }

  &.betslipBMobile {
      .overlay-wrapper {
        .infoBar {
          position: relative;
        }
      }

      .betslipA {
        ::v-deep .betslip {
          min-width: 360px;
          width: 100%;
          bottom: 40px;
      }
    }
  }

  .betslipA {
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: 56px;

    .slide-enter-active {
      transition: all 0.3s cubic-bezier(0,.93,.55,1.01);
      animation: slideUp 0.3s;
    }

    .slide-leave-active {
      transition: all 0.3s cubic-bezier(0,.93,.55,1.01);
      animation: slideDown 0.3s;
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(100vh);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.betslipB {
  &.wideScreenLayout {
    .betslipA {
      width: 300px;

      ::v-deep .betslip {
        min-width: 300px;
      }
    }
  }
}
</style>
