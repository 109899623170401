export default {
  config: {
    productName: 'Lucky Six',
  },
  payinDisabled: true,
  requestDisabled: false,
  numbersFormat: '0,0.00',
  timeToRemoveNotification: 3000,
  ticketsHistory: [],
  expandTicketHistory: true,
  isFullScreen: false,
  streamUuid: null,
  isAudioOn: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};
