import { defaultToAny } from '../../../utility';
import types from './mutationTypes';

export default {
  data() {
    return {
      timeout: null,
    };
  },
  clearNotification({ commit, getters }, payload) {
    if (payload && payload.type) {
      if (getters.notification.type === payload.type) {
        commit(types.CLEAR_NOTIFICATION);
        commit(types.SET_CALLBACK, null);
      }
    } else {
      commit(types.CLEAR_NOTIFICATION);
      commit(types.SET_CALLBACK, null);
    }
  },
  setNotification({
    commit, dispatch, state, rootGetters,
  }, payload) {
    const {
      type,
      message,
      local,
      notificationTimeout,
      callback,
    } = payload;

    if (callback) {
      commit(types.SET_CALLBACK, callback);
    }

    if (notificationTimeout) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        dispatch('clearNotification', {
          type,
        });
      }, state.notificationTimeout);
    }

    // get message from translations, or pass message directy
    commit(types.SET_NOTIFICATION, {
      type,
      value: defaultToAny(
        rootGetters?.translations?.[local],
        rootGetters?.localTranslations?.[local],
        rootGetters?.translations?.[message],
        rootGetters?.localTranslations?.[message],
        message,
      ),
      status: payload.status,
    });
  },
};
