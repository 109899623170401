import { httpStatistics } from './http';

export default {
  getEndpoint(platformName) {
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const path = `VUE_APP_${platform}_STATISTICS_PATH`;
    return process.env[path];
  },
  getStatistics(config) {
    const { platformName, productName, productId } = config;
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const url = this.getEndpoint(platform);
    const params = {
      cpvUuid: productId,
      product: productName,
    };

    return httpStatistics(config).get(url, { params });
  },
};
