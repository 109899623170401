import currentApp from './app';
/**
*
* Set a value in an object based on the version of Vue being used
*
*/
export default function setValue(obj, key, value) {
  const Vue = require('vue');
  if (currentApp.isVue2()) {
    // Vue 2
    Vue.set(obj, key, value);
  } else {
    // Vue 3
    Object.assign(obj, { [key]: value });
  }
}
