import {
  map, isNil, findIndex, chunk, cloneDeep, pick,
} from 'lodash';
import {
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';
import {
  ticketCancel, ticket, lastTickets, ticketsHistory, ticketCheck,
} from '../../../api';
import types from './mutationTypes';
import eventBus from '../../../utility/eventBus';
import setValue from '../../../utility/setValue';

export default {
  addBet({
    state, commit, dispatch, getters, rootGetters,
  }, payload) {
    const { rules: { maxBetNumber } } = rootGetters;
    const { numOfBets } = getters;
    dispatch('setIsQuickpayEnabled', false);
    if (numOfBets < maxBetNumber.value) {
      if (payload.reverse) {
        const betPayload = cloneDeep(payload);
        const minReverseForecastPayment = payload.combinations
          * rootGetters.rules.minCombBetAmount.value;
        betPayload.payment = getters.paymentPerBet > minReverseForecastPayment
          ? getters.paymentPerBet : minReverseForecastPayment;
        if (getters.paymentPerBet < minReverseForecastPayment) {
          dispatch('setPaymentPerBetValue', minReverseForecastPayment);
        }
        commit(types.ADD_NEW_BET, betPayload);
      } else if (payload.reverseTricast) {
        const betPayload = cloneDeep(payload);
        const minReverseTricastPayment = payload.combinations
          * rootGetters.rules.minCombBetAmount.value;
        betPayload.payment = getters.paymentPerBet > minReverseTricastPayment
          ? getters.paymentPerBet : minReverseTricastPayment;
        if (getters.paymentPerBet < minReverseTricastPayment) {
          dispatch('setPaymentPerBetValue', minReverseTricastPayment);
        }
        commit(types.ADD_NEW_BET, betPayload);
      } else {
        commit(types.ADD_NEW_BET, payload);
      }

      if (state.betslipComponents.length) {
        dispatch('setActiveBetslipTab', state.betslipComponents[0]);
      }

      if (getters.isPaymentPerBetActive && getters.resetStakeAfterBetRemove) {
        const payment = (getters.paymentPerBet && getters.isPaymentModified)
          ? getters.paymentPerBet : payload.bet?.payment || payload.payment;
        dispatch('calculateAndUpdateBetValue', { bet: payload, payment }).then(() => {
          dispatch('validateAllBets');
          dispatch('setTotalStakeValue');
        });
      }
      if (getters.isPaymentPerBetActive && !getters.resetStakeAfterBetRemove) {
        const payment = (getters.paymentPerBet && !getters.isPaymentModified)
          ? getters.minPaymentPerBet : payload.bet?.payment || getters.paymentPerBet;
        dispatch('calculateAndUpdateBetValue', { bet: payload, payment }).then(() => {
          dispatch('validateAllBets');
          dispatch('setTotalStakeValue');
        });
      }

      dispatch('addPreBet', {
        bet: '',
        valid: false,
      });
    } else {
      const message = rootGetters
        .translations.general_max_bet_count_rule
        .supplant({ value: maxBetNumber.value });
      dispatch('notifications/setNotification', {
        message,
        notificationTimeout: true,
        status: 'neutral',
      }, { root: true });

      eventBus.$emit('overMaxBetCount', message);
    }
  },
  addPreBet({ commit, dispatch, getters }, payload) {
    commit(types.ADD_PRE_BET, payload);

    if (getters.isQuickpayEnabled) {
      // Use numOfBets in order to not disable payin button if there are bets on Betslip
      if (payload.valid || getters.numOfBets) {
        dispatch('disablePayinButton', false);
      } else {
        dispatch('disablePayinButton', true);
      }
      dispatch('checkBetValidity');
    }
  },
  checkBetValidity({ commit, getters }) {
    const bets = getters.tickets.length ? getters.tickets : [getters.selectedBet];
    bets.forEach((bet) => {
      commit(types.SET_BET_VALIDITY, bet.valid);
    });
  },
  cancelTicket({ dispatch, rootGetters }, id) {
    const { config } = rootGetters;
    const user = rootGetters['user/user'];

    dispatch('setLastTicketsLoader', true);

    ticketCancel
      .cancel(config, user, id)
      .catch((error) => {
        console.log('There was error on cancelTicket', error);
        dispatch(
          'notifications/setNotification',
          {
            message: error, // todo error message?
            status: 'negative',
          },
          { root: true },
        );
      });
  },
  clearBetslip({ commit, dispatch, getters }) {
    commit(types.PAYMENT_MODIFIED, false);
    commit(types.CLEAR_BETSLIP);
    commit(types.UPDATE_FUTURE, 1);
    if(getters.isPaymentPerBetActive){
      dispatch('setTotalStakeValue');
    }else{
      dispatch('updateStake', getters.minPayment);
    }
    dispatch('notifications/clearNotification', null, { root: true });
  },
  disablePayinButton({ commit }, payload) {
    commit(types.DISABLE_PAYIN_BUTTON, payload);
  },
  disableFreeBetButton({ commit }, payload) {
    commit(types.DISABLE_FREE_BET_BUTTON, payload);
  },
  // Dummy format, game can set it's own format and override this one
  formatPlayerTickets(state, payload) {
    return map(payload, (tickets) => {
      let bets = [];
      bets = map(tickets.bets, bet => ({
        id: bet.id,
        status: bet.status,
        round: bet.eventId,
        market: bet.typeValue,
        outcome: bet.value,
        stake: bet.amount,
        odd: bet.odd,
        eventValue: bet.eventValue,
      }));
      return {
        id: tickets.id,
        payout: tickets.payout,
        payin: tickets.payin,
        payinTax: tickets.payinTax,
        superBonus: tickets.superBonus,
        createdAt: tickets.createdAt,
        status: tickets.status,
        maxPossibleWin: tickets.maxPossibleWin,
        bets,
        freeBet: tickets.freeBet,
      };
    });
  },
  getLastTickets({ dispatch, rootGetters }) {
    const { config } = rootGetters;
    const user = rootGetters['user/user'];
    dispatch('setLastTicketsLoader', true);
    lastTickets.getLastTickets(config, user).then((response) => {
      if (response.data) {
        // eslint-disable-next-line
        const isFormatDefined = !!(this._actions && this._actions.formatPlayerTickets);
        dispatch('formatPlayerTickets', response.data, { root: isFormatDefined })
          .then((tickets) => {
            dispatch('setPlayerTickets', tickets);
          });
      }
    }).catch((error) => {
      console.error('last tickets error ', error);
    }).then(() => {
      dispatch('setLastTicketsLoader', false);
    });
  },
  async getTicketsHistory({ dispatch, rootGetters }, payload = {
    dateFrom: format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
    dateTo: format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
  }) {
    const { config } = rootGetters;
    const user = rootGetters['user/user'];

    try {
      const response = await ticketsHistory.getTicketsHistory(config, user, payload);

      if (response.data) {
        if (response.data.length > 0) {
          // eslint-disable-next-line
          const isFormatDefined = !!(this._actions && this._actions.formatPlayerTickets);
          const formattedTickets = await dispatch('formatPlayerTickets', response.data, { root: isFormatDefined });

          dispatch('setTicketsHistory', formattedTickets);
        } else {
          dispatch('setTicketsHistory', []);
        }
      }
      return response.data;
    } catch (error) {
      console.error('tickets history error ', error);
      if (error.response && error.response.data) {
        return error.response.data;
      }
      return error;
    }
  },
  paymentModified({ commit }, payload) {
    commit(types.PAYMENT_MODIFIED, payload);
  },
  rebetTicket({ dispatch }, tickets) {
    const clonedTickets = cloneDeep(tickets);

    dispatch('clearBetslip');

    const getRounds = bets => bets.map(bet => bet.round);

    const isFutureBet = (bets) => {
      const roundIds = getRounds(bets);
      return new Set(roundIds).size !== 1;
    };

    const findIndexesOfMultipleFutureBets = (bets) => {
      const roundIds = getRounds(bets);
      const targetRound = roundIds[0];
      const indexes = [];

      bets.forEach((bet, index) => {
        if (bet.round === targetRound) {
          indexes.push(index);
        }
      });

      return indexes;
    };

    if (isFutureBet(clonedTickets.bets) && !tickets.skipGrouping) {
      const indexesOfFutureBets = findIndexesOfMultipleFutureBets(clonedTickets.bets);
      const isMultiFutureBet = indexesOfFutureBets.length > 1;

      if (isMultiFutureBet) {
        const numberOfBets = indexesOfFutureBets[1];
        const chunksOfBets = chunk(clonedTickets.bets, numberOfBets);
        const roundsIds = getRounds(chunksOfBets[0]);
        const roundDifference = Math.abs(roundsIds[roundsIds.length - 1] - roundsIds[0]);

        chunksOfBets.forEach((betsChunk) => {
          const newBet = betsChunk[0];
          setValue(newBet, 'payment', newBet.stake + newBet.tax);

          dispatch('addBet', newBet);
        });

        dispatch('updateFuture', roundDifference + 1);
      } else {
        const roundIds = getRounds(clonedTickets.bets);
        const roundDifference = roundIds[roundIds.length - 1] - roundIds[0];
        const newBet = clonedTickets.bets[0];

        setValue(newBet, 'payment', newBet.stake + newBet.tax);

        dispatch('addBet', newBet);
        dispatch('updateFuture', roundDifference + 1);
      }
    } else {
      clonedTickets.bets.forEach((bet) => {
        const newBet = bet;
        setValue(newBet, 'payment', bet.stake + bet.tax);
        dispatch('addBet', newBet);
      });
    }

    dispatch('updateStake', clonedTickets.payin);
    dispatch('paymentModified', true);
  },
  removeInvalidBets({ commit }) {
    commit(types.REMOVE_INVALID_BETS);
  },
  removeBet({ commit, dispatch, getters }, payload) {
    // Uncomment this if you want to rest isPaymentModified on bet remove
    // if (!getters.isPaymentPerBetActive) commit(types.PAYMENT_MODIFIED, false);
    commit(types.REMOVE_BET, payload);
    if (!getters.tickets.length) {
      dispatch('clearBetslip');
    }
    if (getters.isPaymentPerBetActive) {
      commit(types.PAYMENT_MODIFIED, false);
      dispatch('validateAllBets');
      dispatch('setTotalStakeValue');
    }
    if (!getters.isPaymentPerBetActive && getters.ticketType.value === 'combo' && getters.numOfBets === 0) {
      commit(types.PAYMENT_MODIFIED, false);
      dispatch('updateStake', getters.minPayment);
    }
  },
  showBetslip({ commit, dispatch, getters }) {
    commit(types.SHOW_BETSLIP);
    if (!getters.isBetslipOpened && getters.tickets.length > 0) {
      dispatch('setActiveBetslipTab', getters.betslipComponents[0]);
    }
  },
  setBetslipConfigValues({ commit }, payload) {
    commit(types.SET_CONFIG_VALUES, payload);
  },
  setTaxes({ commit }, payload) {
    commit(types.SET_TAXES, payload);
  },
  setFutureNumber({ commit }, payload) {
    commit(types.SET_FUTURE_NUMBER, payload);
  },
  setPlayerTickets({ commit }, payload) {
    commit(types.SET_PLAYER_TICKETS, payload);
  },
  setTicketsHistory({ commit }, payload) {
    commit(types.SET_TICKETS_HISTORY, payload);
  },
  switchTickets({ commit }, ticketType) {
    commit(types.SWITCH_TICKETS, ticketType);
  },
  setTicketType({ commit, dispatch, getters }, payload) {
    const activeTicketType = getters.ticketType.type;
    commit(types.SET_TICKET_TYPE, payload);
    dispatch('switchTickets', activeTicketType);
  },
  setIsQuickpayEnabled({ commit }, isQuickpayEnabled) {
    commit(types.SET_IS_QUICKPAY_ENABLED, isQuickpayEnabled);
  },
  ticketPayin({ getters, rootGetters }, {
    payload,
    additionalInfo = null,
  }) {
    const {
      tickets,
      payment,
      selectedBet,
      isQuickpayEnabled,
    } = getters;
    // TODO set payment for paymentPerBetTickets
    // const payment = getters.isPaymentPerBetActive ? getters.totalPayment : getters.payment;
    const { config } = rootGetters;
    const ticketType = rootGetters['betslip/ticketType'].type;
    const user = rootGetters['user/user'];
    const bonus = pick(user?.bonuses[0], ['id', 'type', 'freeBetAmount']);

    let ticketsForPayin;

    if (isQuickpayEnabled) {
      ticketsForPayin = tickets.length ? tickets : [selectedBet];
    } else {
      ticketsForPayin = tickets;
    }

    const betslip = {
      ticketType,
      tickets: ticketsForPayin,
      payment,
    };
    if (bonus.id && getters.isFreeBetAllowed) betslip.bonuses = [bonus];
    return ticket.payin(config, user, betslip, payload, additionalInfo);
  },
  ticketPayinGeneric({ rootGetters }, payload) {
    const { config } = rootGetters;
    return ticket.genericPayin(config, payload);
  },
  updateTicket({ commit }, payload) {
    commit(types.UPDATE_TICKET, payload);
  },
  updatePlayerTicket({ commit }, payload) {
    commit(types.UPDATE_PLAYER_TICKET, payload);
  },
  updateFuture({ commit, getters, dispatch }, payload) {
    commit(types.UPDATE_FUTURE, payload);
    // update ticket and payment only if payment per bet not enabled
    if (!getters.isPaymentPerBetActive) {
      dispatch('setStakeValue');
    } else {
      dispatch('setTotalStakeValue');
    }
  },
  updateStake({ commit }, payload) {
    commit(types.UPDATE_STAKE, payload);
  },

  // TICKET CALCULATIONS ON BETSLIP
  calculateAndUpdateTicketValues({ dispatch, getters }) {
    // Calculate stake for every bet
    let globaPayment = 0;

    const numEventsList = getters.tickets.map(bet => bet.numEvents);
    let numEventsSum = 0;
    if (numEventsList.length) {
      numEventsSum = numEventsList.reduce((x, y) => x + y);
    }

    getters.tickets.forEach((bet) => {
      const minPayment = Number(getters.stakePerBet(bet));
      let payment = null;

      if (getters.isFuturePerBetActive) {
        payment = getters.isPaymentModified ? getters.payment / numEventsSum : bet.minPayment;
      } else {
        payment = getters.stakePerBet(bet) + getters.stakeDifference;
      }
      globaPayment += payment;

      // Update vuex store tickets
      dispatch('updateTicket', { bet, minPayment, payment });
    });
    return globaPayment;
  },
  // Bet payment for single bet
  calculateAndUpdateBetValue({ getters, dispatch }, payload) {
    const { bet } = payload;
    let payment = 0;
    if (isNil(payload.payment)) {
      if (getters.isPaymentPerBetActive && getters.isPaymentModified) {
        payment = getters.paymentPerBet;
      } else {
        payment = payload.maxPayment ? Number(payload.maxPayment) : getters.stakePerBet(bet);
      }
    } else {
      payment = Number(payload.payment);
    }
    const minPayment = Number(getters.stakePerBet(bet));
    // Update vuex store tickets
    dispatch('updateTicket', { bet, minPayment, payment });
  },
  async setStakeValue({ dispatch, getters }, stake) {
    // TODO simplify this function (if / else)
    // eslint-disable-next-line no-param-reassign
    if (stake) stake = Number(stake);
    // calculate ticket (bet) values based on calculations strategy
    if (getters.isPaymentPerBetActive) {
      await dispatch('updateStake', stake);
      // Validate stake
      dispatch('validateStake', stake).then(() => dispatch('validateAllBets', stake));
    } else {
      let newStake = stake;
      if (isNil(newStake)) {
        newStake = getters.isPaymentModified ? getters.payment : getters.minPayment;
      }

      if (getters.ticketType.value !== 'combo' || getters.isPaymentModified) {
        const finalStake = getters.isFuturePerBetActive ? stake : newStake;
        // On stake change
        dispatch('updateStake', finalStake).then(() => {
          // Validate stake
          if (getters.isPaymentModified) {
            dispatch('validateStake', finalStake).then((isValid) => {
              if (isValid) {
                // If stake valid calculate ticket (bet) values
                dispatch('calculateAndUpdateTicketValues');
              }
            });
          }
          dispatch('calculateAndUpdateTicketValues');
        });
      }
    }
  },
  setTotalStakeValue({ getters, commit }) {
    let total = 0;
    getters.tickets.forEach((bet) => {
      // Calculate total stake
      total += getters.isFuturePerBetActive
        ? +bet.payment * bet.numEvents
        : +bet.payment * getters.future;
    });
    if(total === 0){
      total = getters.minPaymentPerBet;
    }
    commit(types.SET_TOTAL_STAKE_VALUE, Number(total.toFixed(getters.toRoundCalc)));
  },
  // validation for every bet
  validateAllBets({ getters, dispatch }, payment) {
    getters.tickets.forEach((bet) => {
      dispatch('validateBetStake', { bet, payment }).then((valid) => {
        if (!valid) {
          // if !isValid disable payin button
          dispatch('disablePayinButton', !valid);
          if (getters.isPaymentPerBetActive) {
            if (bet.payment > getters.maxBetAmount) {
              bet.payment = getters.maxBetAmount;
            }
            if (bet.payment < getters.minBetAmount) {
              bet.payment = getters.minBetAmount;
            }
          }
        }
      });
    });
    if (getters.isPaymentPerBetActive && !getters.isPaymentModified
        && getters.resetStakeAfterBetRemove) {
      dispatch('updateStake', '');
    }
  },
  validateBetStake({ dispatch, getters, rootGetters }, payload) {
    let isValid = true;
    let message = null;
    const bet = !payload.bet ? payload : payload.bet;
    const payment = !payload.payment ? bet.payment : payload.payment;
    const minPayment = getters.stakePerBet(bet);

    if (getters.ticketType.value !== 'combo') {
      // Payment < minPayment
      if (Number(payment) < minPayment) {
        message = {
          message: rootGetters.translations.general_min_bet_amount_rule.supplant({
            value: minPayment.toFixed(getters.toRound)
          }),
          notificationTimeout: false,
        }
        eventBus.$emit('underMinPayment', bet);
        isValid = false;
      }
    }
    if (Number(payment) > rootGetters.rules.maxBetAmount.value) {
      message = {
        message: rootGetters.translations.general_max_bet_amount_rule.supplant({
          value: rootGetters.rules.maxBetAmount.value.toFixed(getters.toRound),
        }),
        notificationTimeout: false,
      }
      eventBus.$emit('overMaxPayment', bet);
      isValid = false;
    }
    // TODO MAX payment and notification message
    // TODO maxWinnig etc..

    // Update bet valid prop
    dispatch('updateTicket', {
      bet, valid: isValid, minPayment, payment,
    });

    eventBus.$emit('setMessage', message);
    // TODO maxWinnig etc..
    // if !isValid disable payin button
    dispatch('disablePayinButton', !isValid);
    // Return true / false
    return isValid;
  },
  validateStake({ dispatch, getters, rootGetters }, payment) {
    let isValid = true;
    let message = null;
    let numberOfBets = 0;
    eventBus.$emit('setMessage', message);
    getters.tickets.forEach((bet) => {
      numberOfBets += bet.numEvents;
    });
    // for empty betslip to check max payin for one bet
    if (numberOfBets < 1) numberOfBets = 1;
    // If payment not modified no need to validate
    if (!getters.isPaymentModified) return true;
    const min = getters.isPaymentPerBetActive && !getters.isFuturePerBetActive
    ? rootGetters.rules.minBetAmount.value : getters.minPayment;
    const max = rootGetters.rules.maxBetAmount.value * numberOfBets;
    
    if (getters.isPaymentPerBetActive && payment === "") {
      isValid = true;
    } else {
      // Payment < minPayment
      if (Number(Number(payment).toFixed(getters.toRoundCalc))
        < Number(Number(min).toFixed(getters.toRoundCalc))) {
        message = {
          message: rootGetters.translations.general_min_bet_amount_rule.supplant({
            value: getters.isPaymentPerBetActive
             ? min.toFixed(getters.toRound) : getters.minPayment.toFixed(getters.toRound),
          }),
          notificationTimeout: false,
        };
        eventBus.$emit('underMinPayment');
        isValid = false;
      }
      // Payment > maxPayment
      // eslint-disable-next-line max-len
      if (Number(Number(payment).toFixed(getters.toRoundCalc)) > Number(Number(max).toFixed(getters.toRoundCalc))) {
        message = {
          message: rootGetters.translations.general_max_bet_amount_rule.supplant({
            value: rootGetters.rules.maxBetAmount.value.toFixed(getters.toRound),
          }),
          notificationTimeout: false,
        };
        eventBus.$emit('overMaxPayment');
        isValid = false;
      }
    }

    // TODO maxWinnig etc..
    dispatch('setIsPaymentValid', isValid);
    if (!getters.isPaymentPerBetActive) {
      eventBus.$emit('setMessage', message);
    }
    if (message) {
      dispatch('notifications/setNotification', message, { root: true });
    } else {
      dispatch('notifications/clearNotification', null, { root: true });
    }
    // if !isValid disable payin button
    dispatch('disablePayinButton', !isValid);
    if (getters.isQuickpayEnabled) {
      dispatch('checkBetValidity');
    }
    // Return true / false
    return isValid;
  },
  setEditBet({ commit }, payload) {
    commit(types.SET_EDIT_BET, payload);
  },
  setLastTicketsLoader({ commit }, payload) {
    commit(types.LAST_TICKETS_IN_PROGRESS, payload);
  },
  setTicketPayinLoader({ commit }, payload) {
    commit(types.TICKET_PAYIN_IN_PROGRESS, payload);
  },
  ticketCheck({ rootGetters }, requestUuid) {
    const { config } = rootGetters;
    const user = rootGetters['user/user'];
    return ticketCheck.check(config, user, requestUuid);
  },
  setBetslipComponents({ commit }, payload) {
    commit(types.SET_BETSLIP_COMPONENTS, payload);
  },
  setActiveBetslipTab({ commit }, payload) {
    if (payload.disabled) return;
    commit(types.SET_ACTIVE_BETSLIP_TAB, payload);
  },
  setTicketTypeComponents({ commit }, payload) {
    commit(types.SET_TICKET_TYPE_COMPONENTS, payload);
  },
  setTicketTypeActiveComponent({ commit }, payload) {
    commit(types.SET_TICKET_TYPE_ACTIVE_COMPONENT, payload);
  },
  disableLastTickets({ commit }, payload) {
    commit(types.DISABLE_LAST_TICKETS, payload);
  },
  /**
   * Add betslip blockers for shop
   */
  setBetslipBlockers: ({ commit, state }, payload) => {
    let i = 0;
    const newBetslipBlockers = state.betslipBlockers;
    const checkArray = () => findIndex(
      newBetslipBlockers,
      blocker => blocker.id === payload.blockers[i].id,
    );
    while (i < payload.blockers.length) {
      const index = checkArray();
      if (payload.type === 'add') {
        if (index < 0) {
          newBetslipBlockers.push(payload.blockers[i]);
        }
      } else if (index >= 0) {
        newBetslipBlockers.splice(index, 1);
      }
      i += 1;
    }
    commit(types.SET_BETSLIP_BLOCKERS, newBetslipBlockers);
  },
  setIsPaymentValid({ commit }, isValid) {
    commit(types.SET_IS_PAYMENT_VALID, isValid);
  },
  enableTicketRebet({ commit }, enable) {
    commit(types.ENABLE_TICKET_REBET, enable);
  },
  setMobileVisualizationHeight({ commit }, payload) {
    commit(types.SET_MOBILE_VISUALIZATION_HEIGHT, payload);
  },
  setBetslipFooterHeight({ commit }, payload) {
    commit(types.SET_BETSLIP_FOOTER_HEIGHT, payload);
  },
  setPaymentPerBet({ commit }, payload) {
    commit(types.SET_PAYMENT_PER_BET, payload);
  },
  setScheduleDisabled({ commit }, payload) {
    commit(types.SET_SCHEDULE_DISABLED, payload);
  },
  setPaymentPerBetValue({ commit }, payload) {
    commit(types.SET_PAYMENT_PER_BET_VALUE, payload);
  },
  setResetStakeAfterBetRemove({ commit }, payload) {
    commit(types.SET_RESET_STAKE_AFTER_BET_REMOVE, payload);
  },
  setFuturePerBet({ commit }, payload) {
    commit(types.SET_FUTURE_PER_BET, payload);
  },
  lockBetslip({ commit }, payload) {
    commit(types.LOCK_BETSLIP, payload);
  },
};
