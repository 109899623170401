<template>
  <div>
    <Actions v-if="showActions"></Actions>
    <CircleLoader class="circle-loader"
                  :loader="isLastTicketsInProgress"></CircleLoader>
    <Scrollable v-if="showActions">
      <div class="last-tickets-wrapper"
        :style="{ height: isMobile ? mobileLastTicketsHeight : wrapperHeight }">
        <div class="last-tickets-body">
          <div class="no-tickets-message"
               v-if="!playerTickets.length">
            <i class="icon icon-betslip"></i>
            <span>{{ translations.general_tickets_empty_info }}</span>
          </div>
          <TicketItem :isBallDrawn="isBallDrawn"
                      v-for="(ticket, key) in playerTickets"
                      :ticket="ticket"
                      :productNameLabel="productNameLabel"
                      :key="key">
          </TicketItem>
        </div>
      </div>
    </Scrollable>
    <div v-else class="last-tickets-wrapper"
        :style="{ height: isMobile ? 'auto' : wrapperHeight }">
        <div class="last-tickets-body">
          <div class="no-tickets-message"
               v-if="!playerTickets.length">
            <i class="icon icon-betslip"></i>
            <span>{{ translations.general_tickets_empty_info }}</span>
          </div>
          <TicketItem :isBallDrawn="isBallDrawn"
                      v-for="(ticket, key) in playerTickets"
                      :ticket="ticket"
                      :productNameLabel="productNameLabel"
                      :key="key">
          </TicketItem>
        </div>
      </div>
  </div>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import Scrollable from '../../Scrollable';
import CircleLoader from '../../CircleLoader';
import Actions from './LastTicketsActions';
import TicketItem from './LastTicketsBetItem';

export default {
  name: 'LastTickets',
  components: {
    Actions,
    TicketItem,
    Scrollable,
    CircleLoader,
  },
  props: {
    isBallDrawn: {
      type: Function,
      default: undefined,
    },
    productNameLabel: {
      type: String,
      default: '',
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['activeTicketTypes', 'isLastTicketsInProgress', 'mobileLastTicketsHeight']),
    ...mapGetters({
      playerTickets: 'gamesBetslip/playerTickets',
      isPaymentPerBetActive: 'betslip/isPaymentPerBetActive',
      user: 'user/user',
      translations: 'translations',
      isMobile: 'isMobile',
    }),
    hasMultipleTicketTypes() {
      return this.activeTicketTypes.length > 1;
    },
    isCountdown() {
      if (!isNil(this.$store.getters.isStartCountdownEvent)) {
        return this.$store.getters.isStartCountdownEvent;
      }

      return null;
    },
    getPayPerBetHeight() {
      return this.isPaymentPerBetActive ? 40 : 0;
    },
    wrapperHeight() {
      if (this.hasMultipleTicketTypes) {
        return 'calc(var(--footer-height) + 352px + 8px)';
      }

      return 'calc(var(--footer-height) + 352px - 40px)';
    },
  },
};
</script>

<style lang="scss">
.last-tickets-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  background: var(--background-1);
}

.last-tickets-body {
  height: 100%;
  position: relative;
  .no-tickets-message {
    position: absolute;
    top: 25%;
    left: 0;
    display: flex;
    flex-wrap: wrap;

    .icon {
      width: 100%;
      text-align: center;
      font-size: 72px;
      color: var(--text-primary-3);
    }
    span {
      margin-top: 66px;
      font-size: 16px;
      width: 100%;
      float: left;
      padding: 0 40px;
      text-align: center;
      color: var(--text-primary-3);
    }
  }
}
</style>
