import { httpLastTickets } from './http';
import gamesHeaders from './headers';

export default {
  getEndpoint(platformName) {
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const path = `VUE_APP_${platform}_LASTTICKETS_PATH`;
    return process.env[path];
  },
  getLastTickets(config, user) {
    const { platformName } = config;
    const url = this.getEndpoint(platformName);
    const excludeStatusList = ['CLOSED', 'REJECTED', 'AFTERCLOSED'];

    const params = {
      count: 5,
      id_language: config.locale,
      notstatus: excludeStatusList.toString(),
      player: user.profile.username,
      product: config.productFqn,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const headers = {
      ...gamesHeaders.getAuthHeader(user, config),
      ...gamesHeaders.getNgsHeaders(config, user),
    };

    return httpLastTickets(config).get(url, { params, headers });
  },
};
