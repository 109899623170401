export default {
  ADD_NEW_BET: 'ADD_NEW_BET',
  ADD_PRE_BET: 'ADD_PRE_BET',
  CLEAR_BETSLIP: 'CLEAR_BETSLIP',
  DISABLE_PAYIN_BUTTON: 'DISABLE_PAYIN_BUTTON',
  DISABLE_FREE_BET_BUTTON: 'DISABLE_FREE_BET_BUTTON',
  PAYMENT_MODIFIED: 'PAYMENT_MODIFIED',
  REMOVE_BET: 'REMOVE_BET',
  REMOVE_INVALID_BETS: 'REMOVE_INVALID_BETS',
  SHOW_BETSLIP: 'SHOW_BETSLIP',
  SET_TAXES: 'SET_TAXES',
  SET_CONFIG_VALUES: 'SET_CONFIG_VALUES',
  SET_FUTURE_NUMBER: 'SET_FUTURE_NUMBER',
  SET_PLAYER_TICKETS: 'SET_PLAYER_TICKETS',
  SET_TICKETS_HISTORY: 'SET_TICKETS_HISTORY',
  SET_TICKET_TYPE: 'SET_TICKET_TYPE',
  SET_TOTAL_STAKE_VALUE: 'SET_TOTAL_STAKE_VALUE',
  UPDATE_FUTURE: 'UPDATE_FUTURE',
  UPDATE_STAKE: 'UPDATE_STAKE',
  UPDATE_TICKET: 'UPDATE_TICKET',
  UPDATE_PLAYER_TICKET: 'UPDATE_PLAYER_TICKET',
  SET_EDIT_BET: 'SET_EDIT_BET',
  SWITCH_TICKETS: 'SWITCH_TICKETS',
  SET_TOTAL_PAYMENT_PER_TICKET: 'SET_TOTAL_PAYMENT_PER_TICKET',
  LAST_TICKETS_IN_PROGRESS: 'LAST_TICKETS_IN_PROGRESS',
  TICKET_PAYIN_IN_PROGRESS: 'TICKET_PAYIN_IN_PROGRESS',
  SET_BETSLIP_COMPONENTS: 'SET_BETSLIP_COMPONENTS',
  SET_ACTIVE_BETSLIP_TAB: 'SET_ACTIVE_BETSLIP_TAB',
  SET_TICKET_TYPE_ACTIVE_COMPONENT: 'SET_TICKET_TYPE_ACTIVE_COMPONENT',
  SET_TICKET_TYPE_COMPONENTS: 'SET_TICKET_TYPE_COMPONENTS',
  DISABLE_LAST_TICKETS: 'TOGGLE_LAST_TICKETS_DISABLE',
  SET_BETSLIP_BLOCKERS: 'SET_BETSLIP_BLOCKERS',
  SET_IS_QUICKPAY_ENABLED: 'SET_IS_QUICKPAY_ENABLED',
  ENABLE_TICKET_REBET: 'ENABLE_TICKET_REBET',
  SET_IS_PAYMENT_VALID: 'SET_IS_PAYMENT_VALID',
  SET_MOBILE_VISUALIZATION_HEIGHT: 'SET_MOBILE_VISUALIZATION_HEIGHT',
  SET_BETSLIP_FOOTER_HEIGHT: 'SET_BETSLIP_FOOTER_HEIGHT',
  SET_BET_VALIDITY: 'SET_BET_VALIDITY',
  SET_PAYMENT_PER_BET: 'SET_PAYMENT_PER_BET',
  SET_SCHEDULE_DISABLED: 'SET_SCHEDULE_DISABLED',
  SET_PAYMENT_PER_BET_VALUE: 'SET_PAYMENT_PER_BET_VALUE',
  SET_RESET_STAKE_AFTER_BET_REMOVE: 'SET_RESET_STAKE_AFTER_BET_REMOVE',
  SET_FUTURE_PER_BET: 'SET_FUTURE_PER_BET',
  LOCK_BETSLIP: 'LOCK_BETSLIP',
};
