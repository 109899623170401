<template>
  <div class="footer-wrapper">
    <div class="footer">
      <div class="left">
        <div class="button"
             v-if="!disabledCancelButton"
             :class="{disabled: disabledCancelButton}"
             @click="openCancelApprove">
          {{ translations.general_cancel_ticket }}
        </div>
      </div>
      <div class="right-wrapper">
        <div class="right">
          <div class="button"
               v-if="isTicketRebetAllowed"
               @click="rebet">
            {{ translations.general_rebet }}
          </div>
          <div class="button"
               @click="closeDetails">
            {{ translations.general_close }}
          </div>
        </div>
      </div>
    </div>
    <div class="cancel-ticket-wrapper-desktop">
      <Modal v-if="showCancelTicket"
             :width="isTablet ? '400px' : undefined"
             showFooter
             class="cancel-ticket"
             @closeModal="closeCancelTicketModal"
             @closeModalOutside="closeCancelTicketModal('outside')"
             ref="modal">
        <template v-slot:header>
          {{ translations.general_cancel_ticket }}?
        </template>
        <template v-slot:body>
          {{ translations.general_cancel_ticket_confirm }} {{ticket.id}}
        </template>
        <template v-slot:footer>
          <button class="button"
                  @click="closeCancelTicketModal">
            {{ translations.general_no }}
          </button>
          <button class="button"
                  @click.once="approveTicketCancel">
            {{ translations.general_yes }}
          </button>
        </template>
      </Modal>
    </div>
    <div class="cancel-ticket-wrapper-mobile">
      <Modal v-if="showCancelTicket"
             showFooter
             class="cancel-ticket"
             :popupMobileAnimation="mobilePopupAnimation"
             :width="isTablet ? '400px' : undefined"
             @closeModal="closeCancelTicketModal"
             @closeModalOutside="closeCancelTicketModal('outside')"
             ref="modal">
        <template v-slot:header>
          {{ translations.general_cancel_ticket }}?
        </template>
        <template v-slot:body>
          <div class="modal-body">
            {{ translations.general_cancel_ticket_confirm }} {{ticket.id}}
          </div>
        </template>
        <template v-slot:footer>
          <button class="button"
                  @click="closeCancelTicketModal">
            {{ translations.general_no }}
          </button>
          <button class="button"
                  @click.once="approveTicketCancel">
            {{ translations.general_yes }}
          </button>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '../../Modal';
import { eventBus } from '../../../utility';

export default {
  name: 'LastTicketsDetailsFooter',
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      showCancelTicket: false,
      mobilePopupAnimation: true,
    };
  },
  computed: {
    ...mapGetters({
      config: 'gamesBetslip/config',
      translations: 'translations',
      isTablet: 'isTablet',
    }),
    isTicketCancelAllowed() {
      return this.config.isTicketCancelAllowed;
    },
    isTicketRebetAllowed() {
      return this.config.isTicketRebetAllowed;
    },
    disabledCancelButton() {
      if (this.isTicketCancelAllowed && this.ticket.status.id === '0' && !this.hasBonuses) {
        return false;
      }
      return true;
    },
    hasBonuses() {
      return this.ticket.bonuses && this.ticket.bonuses.length;
    },
  },
  methods: {
    ...mapActions({
      cancelTicket: 'gamesBetslip/cancelTicket',
      rebetTicket: 'gamesBetslip/rebet',
    }),
    closeDetails() {
      this.$emit('closeDetails');
    },
    closeCancelTicketModal(option) {
      if (option === 'outside') {
        this.showCancelTicket = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showCancelTicket = false;
        }, 500);
      }
    },
    openCancelApprove() {
      this.showCancelTicket = true;
    },
    rebet() {
      if (this.ticket) {
        this.rebetTicket(this.ticket).then(() => {
          this.$root.$emit('TicketRebet');
          eventBus.$emit('TicketRebet');
        });
        this.closeDetails();
      }
    },
    approveTicketCancel() {
      this.cancelTicket(this.ticket.id);
      this.closeCancelTicketModal();
      this.$root.$emit('TicketCancelApproved');
      eventBus.$emit('TicketCancelApproved');
      setTimeout(() => {
        this.closeDetails();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  font-family: Roboto, 'sans-serif';
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .button {
      background: transparent;
      font-size: 14px;
      color: var(--secondary);
      text-transform: uppercase;
      cursor: pointer;
      font-family: Roboto, 'sans-serif';
    }
    .left {
      .button {
        &.disabled {
          opacity: 0.3;
          pointer-events: none;
          user-select: none;
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;

      .button {
        margin-left: 54px;
      }
    }
  }
  .cancel-ticket {
    &::v-deep .modal {
        .modal-header {
          & > h3 {
          margin: 0;
        }
      }
    }

    .button {
      background: transparent;
      border: 0;
      outline: 0;
      font-size: 14px;
      color: #ffc107;
      text-transform: uppercase;
      margin-left: 30px;
      cursor: pointer;
      font-family: Roboto, 'sans-serif';
    }
    ::v-deep .modal-footer {
      float: right;
      padding: 30px;
      position: relative;
    }
  }
}
@media (max-width: 660px) {
  .footer-wrapper {
    .footer {
      padding: 28px 32px;
      .right-wrapper{
        width:auto;
      }
    }
    .cancel-ticket-wrapper-desktop {
      display: none;
    }
    .cancel-ticket-wrapper-mobile {
      .cancel-ticket {
        background-color: var(--overlay-lock);
        ::v-deep {
          .modal {
            background-color: var(--card);
            color: var(--text-primary-1);
            height: 200px !important;

            .modal-header {
              background-color: var(--card);
              padding: 24px 16px 24px 16px;
            }

            .modal-body {
              position: relative;
              padding: 0 8px 0 8px;

              .modal-actions {
                position: absolute;
                bottom: 0;
                right: 0;
                padding-right: 43px;
              }
            }

            .modal-footer {
              position: relative;
              border: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 661px) {
  .footer-wrapper {
    .footer {
      padding: 0 44px;
      .button {
        padding: 55px 0 27px 0;
      }
    }
  }
  .cancel-ticket-wrapper-mobile {
    display: none;
  }
}
</style>
