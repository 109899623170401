import types from './mutationTypes';

export default {
  [types.UPDATE_USER](state, payload) {
    state.user.auth = payload.auth; // { auth / profile }
    state.user.profile = payload.profile;
  },
  [types.SET_USER_BALANCE](state, payload) {
    state.user.balance = payload;
  },
  [types.SET_TERMINAL_USER](state, payload) {
    state.isTerminalUser = payload;
  },
  [types.SET_USER_BONUSES](state, payload) {
    state.user.bonuses = payload;
  },
};
