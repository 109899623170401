import axios from 'axios';

const supportedLocales = ['en', 'sr-Latn', 'tr', 'hr', 'cs', 'ht', 'ro', 'az'];

const http = axios.create({
  baseURL: process.env.VUE_APP_GAMES_STATIC,
  timeout: 5000,
});

export default {
  async getTranslations(locale) {
    const { data } = await http.get(`/i18n/${supportedLocales.includes(locale) ? locale : 'en'}.json`);
    return data;
  },
  getHelp(helpPath) {
    return `${process.env.VUE_APP_GAMES_STATIC}/help/${helpPath}`;
  },
};
