import { uuid } from 'vue-uuid';

const socketQuery = {
  retail() {},
  terminal() {},
  web(config) {
    const connectionTime = new Date().toISOString();
    const requestUuid = uuid.v4();
    const { productName } = config.product;

    return `clientType=user&clientSubType=Player&connectionTime=${connectionTime}&requestUuid=${requestUuid}&productName=${productName}`;
  },
};

export default socketQuery;
