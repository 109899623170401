<template>
  <div class="helpWrapper">
    <iframe :src="helpPath" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'Help',
  props: {
    helpPath: String,
  },
};
</script>

<style lang="scss" scoped>
.helpWrapper {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
