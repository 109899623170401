import { capitalize } from 'lodash';
import { uuid } from 'vue-uuid';

export default {
  getAuthHeader(user, config) {
    const platform = config.platformName;
    if (config.applicationName === 'Terminal') {
      return {
        Authorization: `Basic ${btoa(`${config.account.uuid}:${config.account.password}`)}`,
      };
    }
    const authHeaders = {
      seven: {
        Authorization: `Bearer ${user.auth.token}`,
      },
      playtech: {
        Authorization: `Bearer ${user.auth.token}`,
        'X-AUTH-TOKEN': user.auth.token,
      },
    };
    return authHeaders[platform];
  },
  getNgsHeaders(config, user) {
    return {
      'X-Nsft-Ngs-Company': config.tenantId,
      'X-Nsft-Ngs-Player': user.profile.id,
      'X-Nsft-Ngs-Product': config.productId,
      'X-Request-ID': uuid.v4(),
      'SEVEN-LOCALE': config.locale,
      'Accept-language': config.locale,
    };
  },
  getPlatformHeaders(user, config) {
    let terminalHeaders;
    const platform = config.platformName;
    if (config.applicationName === 'Terminal') {
      terminalHeaders = {
        'HTTP-X-NAB-DEVICE-UUID': config.device.uuid,
        'HTTP-X-NAB-BETSHOP-ID': config.betshop.id,
        'HTTP-X-NAB-COMPANY-ID': config.companyData.id,
        'X-NSFT-SEVEN-COMPANY-UUID': config.tenantId,
      };
    }
    const webHeaders = {
      seven: {
        'HTTP-X-NAB-PRODUCTINSTANCE-ID': config.productFqn,
        'HTTP-X-NAB-PRODUCTNAME': config.productFqn,
        'HTTP-X-SEVEN-CLUB-UUID': config.tenantId,
        'HTTP-X-NAB-DP': capitalize(config.applicationName),
        'SEVEN-LOCALE': config.locale,
        'SEVEN-TP-TOKEN': user.auth.tpToken,
      },
      playtech: {
        'X-Nsft-Ngs-Company': config.tenantId,
        'X-Nsft-Ngs-Product': config.productId,
        'X-Request-ID': uuid.v4(),
        'Accept-Language': config.locale,
      },
    };
    const headers = config.applicationName === 'Terminal' ? terminalHeaders : webHeaders[platform];
    return headers;
  },
};
