export default {
  web: {
    name: 'web',
  },
  retail: {
    name: 'retail',
  },
  terminal: {
    name: 'terminal',
  },
  'terminal-large': {
    name: 'terminal-large',
  },
  mobile: {
    name: 'mobile',
  },
};
