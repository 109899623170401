const store = {
  // Default rules -- game should override this
  rules: {
    maxBetAmount: {
      name: 'Max bet amount',
      value: 100,
    },
    maxWinningCap: {
      name: 'Maximum winning limit',
      value: 999999999999,
    },
    minBetAmount: {
      name: 'Min bet amount',
      value: 1,
    },
    minCombBetAmount: {
      name: 'Min combination bet amount',
      value: 0.1,
    },
    minPaymentPerTicket: {
      name: 'Min payment per ticket',
      value: 1,
    },
    maxPaymentPerTicket: {
      name: 'Max payment per ticket',
      value: 100,
    },
    maxBetNumber: {
      name: 'Max bet number',
      value: 10,
    },
  },
};

const types = {
  SET_RULES: 'SET_RULES',
};

export default {
  namespaced: false,
  state: store,
  actions: {
    setRules({ commit }, payload) {
      commit(types.SET_RULES, payload);
    },
  },
  getters: {
    rules: state => state.rules,
  },
  mutations: {
    [types.SET_RULES](state, rules) {
      state.rules = rules;
    },
  },
};
