import { some } from 'lodash';

export default {
  config: state => state.config,
  isBetslipOpened: state => state.isBetslipOpened,
  isBetslipLocked: state => state.isBetslipLocked,
  isSelectedBetValid: state => state.selectedBet.valid,
  isPaymentModified: state => state.isPaymentModified,
  isPayinBtnDisabled: state => state.isPayinBtnDisabled,
  isFreeBetButtonDisabled: state => state.isFreeBetButtonDisabled,
  isPayinTaxActive: state => !state.config.taxes.payin.hideTax,
  isPayoutTaxActive: state => !state.config.taxes.payout.hideTax,
  isPaymentPerBetActive: state => state.config.paymentPerBet,
  isTicketValid: state => !some(state.tickets[state.ticketType.type], bet => bet.locked),
  isQuickpayEnabled: state => state.isQuickpayEnabled,
  isPaymentValid: state => state.isPaymentValid,
  future: state => state.future,
  futureBets: state => state.config.futureBets,
  payment: state => state.payment,
  paymentPerBet: state => state.paymentPerBet,
  resetStakeAfterBetRemove: state => state.resetStakeAfterBetRemove,
  playerTickets: state => state.playerTickets,
  possibleWinPerBet: state => state.config.possibleWinPerBet,
  selectedBet: state => state.selectedBet.value,
  tickets: state => state.tickets[state.ticketType.type],
  ticketsHistory: state => state.ticketsHistory,
  ticketCancelAllowed: state => state.config.ticketCancelAllowed,
  ticketRebetAllowed: state => state.config.ticketRebetAllowed,
  lastTicketsInProgress: state => state.lastTicketsInProgress,
  ticketPayinInProgress: state => state.ticketPayinInProgress,
  taxes: state => state.config.taxes,
  ticketType: state => state.ticketType,
  totalPayment: (state, getters, rootGetters) => {
    let total = 0;
    if (getters.ticketType.value === 'combo') {
      if (!getters.isPaymentModified) {
        total += Number(rootGetters.rules.rules.minBetAmount.value);
      } else {
        total += Number(getters.payment);
      }
    } else {
      return state.totalPayment === 0 ? getters.minPayment : state.totalPayment;
    }
    return Number(total.toFixed(getters.toRoundCalc));
  },
  toRound: () => 2, // Number of decimal places for ui display
  toRoundCalc: () => 5, // Number of decimal places when doing (background) calculations

  // BETSLIP CALCULATIONS
  numOfBets: (state, getters) => getters.tickets && getters.tickets.length,
  minPaymentPerBet: (state, getters, rootGetters) => {
    const minPayment = [];
    const { rules } = rootGetters.rules;
    if (getters.numOfBets === 0) return rules.minBetAmount.value;

    getters.tickets.forEach((bet) => {
      // Calculate min stake based on number of combinations
      minPayment.push(getters.stakePerBet(bet));
    });
    return Math.min(...minPayment);
  },
  minPayment: (state, getters, rootGetters) => {
    let minPayment = 0;
    const { rules } = rootGetters.rules;

    if (getters.numOfBets === 0 && !getters.isQuickpayEnabled) return rules.minBetAmount.value;

    if (getters.numOfBets === 0 && getters.isQuickpayEnabled) {
      minPayment = getters.stakePerBet(getters.selectedBet);

      return minPayment;
    }

    if (getters.ticketType.value === 'combo') {
      minPayment += rules.minBetAmount.value;
    } else {
      getters.tickets.forEach((bet) => {
        // Calculate min stake based on number of combinations
        minPayment += getters.stakePerBet(bet);
      });
    }
    return Number(minPayment.toFixed(getters.toRoundCalc));
  },
  stakeDifference: (state, getters) => {
    if (getters.minPayment >= getters.payment) {
      return 0;
    }
    return (getters.payment - getters.minPayment) / getters.numOfBets;
  },
  stakePerBet: (state, getters, rootGetters) => (bet) => {
    const { rules } = rootGetters.rules;

    let stake;
    // Calculate minimum stake
    if (bet.combinations > 0) {
      let combinationsStake = Number((rules.minCombBetAmount.value * bet.combinations)
        .toFixed(getters.toRoundCalc));
      if (getters.isFuturePerBetActive) combinationsStake *= bet.numEvents;

      stake = combinationsStake < rules.minBetAmount.value
        ? rules.minBetAmount.value
        : combinationsStake;
    } else {
      stake = rules.minBetAmount.value;
    }

    // if payment perBet is enabled calculate future as bet stake,
    // otherwise future is ignored and only used for "total stake calculations"
    if (!getters.isPaymentPerBetActive) {
      return stake * getters.future;
    }
    return stake;
  },
  editBet: state => state.editBet,
  betslipComponents: state => state.betslipComponents,
  betslipActiveComponent: state => state.betslipActiveComponent,
  ticketTypeActiveComponent: state => state.ticketTypeActiveComponent,
  ticketTypeComponents: state => state.ticketTypeComponents,
  betslipBlockers: state => state.betslipBlockers,
  mobileVisualizationHeight: state => state.mobileVisualizationHeight,
  betslipFooterHeight: state => state.betslipFooterHeight,
  mobileBetslipBodyHeight: (state, getters) => {
    // if only one ticket type height is 0px, if more than one height is 40px
    const ticketTypesHeight = getters.ticketTypeComponents.length === 1 ? 0 : 40;
    const { betslipFooterHeight } = getters;
    // 40 - games header component height, 40 - tabs height on betslip, 56 footer height
    return getters.mobileVisualizationHeight - 40 - ticketTypesHeight
      - betslipFooterHeight - 56;
  },
  isBetValid: state => state.isBetValid,
  isScheduleDisabled: state => state.isScheduleDisabled,
  isFutureActive: (state, getters) => getters.config.futureBets > 1,
  isFuturePerBetActive: state => state.config.futurePerBet,
  freeBet: (state, getters, rootGetters) => rootGetters.user.user.bonuses,
  isFreeBetAllowed: (state, getters) => {
    const freeBetPayment = getters.freeBet[0]?.freeBetAmount;
    if (freeBetPayment) {
      return getters.isPaymentPerBetActive
        ? freeBetPayment === getters.paymentPerBet
        : (freeBetPayment === getters.payment);
    }
    return false;
  },
  maxBetNumber: (state, getters, rootGetters) => rootGetters.rules.rules.maxBetNumber.value,
  maxBetAmount: (state, getters, rootGetters) => rootGetters.rules.rules.maxBetAmount.value,
  minBetAmount: (state, getters, rootGetters) => rootGetters.rules.rules.minBetAmount.value,
};
