import { range } from 'lodash';

export default {
  activeComponent: {},
  activeTicketType: {},
  activeBetslipInput: null,
  betslipBlockers: [],
  config: {
    currency: 'BAM',
    minIncrement: 0.01,
    ticketTypes: ['single', 'combo', 'system'],
    rules: {
      minBetAmount: {
        name: 'Min bet amount',
        value: 1,
      },
      maxBetAmount: {
        name: 'Max bet amount',
        value: 1000,
      },
      minCombBetAmount: {
        name: 'Min combination bet amount',
        value: 0.2,
      },
      minPaymentPerTicket: {
        name: 'Min payment per ticket',
        value: 1,
      },
      maxPaymentPerTicket: {
        name: 'Max payment per ticket',
        value: 1000,
      },
      maxBetNumber: {
        name: 'Max bet number',
        value: 20,
      },
      odds: {
        default: 'Decimal',
        active: 'Decimal',
        supported: [
          'American',
          'Decimal',
        ],
      },
      paymentStrategy: {
        mobile: {
          name: 'Mobile',
          value: 'perTicket',
        },
        retail: {
          name: 'Retail',
          value: 'perTicket',
        },
        web: {
          name: 'Web',
          value: 'perBet',
        },
        terminal: {
          name: 'Terminal',
          value: 'perTicket',
        },
      },
    },
    futureRounds: range(1, 11),
    futureRoundsOrientation: 'bottom',
    taxes: {
      payin: {
        policy: 'BAPayinTax', value: true, hideTax: false,
      },
      payout: {
        policy: 'BAPayoutTax', value: true, hideTax: false,
      },
    },
    isPossibleWinActive: true,
    isPaymentBetAllowed: true,
    isPaymentPerBetAllowed: true,
    isFuturePerBetAllowed: true,
    isFuturePerTicketAllowed: true,
    isTicketCancelAllowed: false,
    isTicketRebetAllowed: false,
    isAddButtonActive: true,
    isPaymentPerBetStrategy: true,
    paymentChips: [0.5, 1, 2, 5, 10, 20],
    betLayout: 'A',
  },
  futureRounds: 1,
  isManualMode: false,
  isPayinInProgress: false,
  isLastTicketsInProgress: false,
  isQuickpayEnabled: false,
  isPayinButtonDisabled: false,
  isPaymentPerBetValid: true,
  isStakeBoxVisible: false,
  isBetAppearAnimationDisabled: false,
  isTotalPaymentValid: true,
  isPaymentValid: true,
  isCantSplitActive: false,
  isUserBalanceInvalid: false,
  minBetPayment: 0,
  minTicketPayment: 0,
  minPaymentAllowed: 0,
  maxPaymentAllowed: 0,
  paymentPerBet: '',
  playerTickets: [],
  possibleWin: {
    possibleWinnings: 0,
    possiblePayinTax: 0,
    possiblePayoutTax: 0,
  },
  possibleWinPreBet: {
    possibleWinnings: 0,
    possiblePayinTax: 0,
    possiblePayoutTax: 0,
  },
  possibleWinCombo: {
    possibleWinnings: 0,
    possiblePayinTax: 0,
  },
  preBet: {},
  totalPayment: 0,
  totalOdds: 0,
  ticket: [],
};
