<template>
  <div class="disable-overlay-wrapper" />
</template>

<script>
export default {
  name: 'DisableOverlay',
};
</script>

<style lang="scss" scoped>
.disable-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: var(--overlay-lock);
}
</style>
