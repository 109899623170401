import notificationTypes from './notificationTypes';

export default {
  callback: state => state.callback,
  isNotificationActive: (state, getters) => getters.notification.value.length > 0,
  notification: state => state.notification,
  showInsufficientFundsNotification: (state, getters, rootState, rootGetters) => rootGetters['user/isLoggedIn']
    && rootGetters['betslip/numOfBets'] > 0
    && !rootGetters['betslip/ticketPayinInProgress']
    && !rootGetters['betslip/isFreeBetAllowed']
    && rootGetters.isBalanceLowerThanPayment,
  notificationTypes: () => notificationTypes,
};
