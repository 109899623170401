import axios from 'axios';
import { routes } from '../../config/index';

export default {
  fetch(lang, cpvUuid, channel) {
    const url = `${routes.tcApi}/domains/${channel}/languages/${lang}/clients/${cpvUuid}`;
    return axios.get(url)
      .then(response => response.data)
      .catch((error) => {
        console.log('Translations error: ', error);
        return error;
      });
  },
};
