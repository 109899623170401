<template>
  <Accordion :isExpanded="isExpanded"
             v-if="ticket">
    <div class="ticket-details-accordion">
      <div class="header-wrapper">
        <LastTicketsDetailsHeader :ticket="ticket" :productNameLabel="productNameLabel" />
      </div>
      <LastTicketsDetailsBody :ticket="ticket" :clickDisabled="true" />
      <div class="footer-wrapper">
        <LastTicketsDetailsFooter :ticket="ticket"
                                  @closeDetails="closeDetails" />
      </div>
    </div>
  </Accordion>
</template>

<script>
import Accordion from '../Accordion';
import LastTicketsDetailsHeader from '../BetslipArea/LastTickets/LastTicketsDetailsHeader';
import LastTicketsDetailsBody from '../BetslipArea/LastTickets/LastTicketsDetailsBody';
import LastTicketsDetailsFooter from '../BetslipArea/LastTickets/LastTicketsDetailsFooter';

export default {
  name: 'TicketsHistoryDetailsAccordion',
  components: {
    Accordion,
    LastTicketsDetailsHeader,
    LastTicketsDetailsBody,
    LastTicketsDetailsFooter,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      default: () => ({}),
    },
    productNameLabel: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeDetails() {
      this.$emit('closeDetails');
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-details-accordion {
  padding: 12px 16px 1px 16px;

  .header-wrapper {
    padding-bottom: 24px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: var(--text-primary-1);
    float: left;
  }

  .footer-wrapper {
    clear: both;
    padding-top: 13px;
  }
}
</style>
