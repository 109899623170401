import io from 'socket.io-client';
import query from './socketQuery';
import eventHandler from './eventHandler';

let socketClient = null;

export default {
  init(url, channel, config) {
    const socketOptions = {
      transports: ['websocket'],
      query: query[channel](config),
    };

    socketClient = io(url, socketOptions);
  },
  subscribe(data, player) {
    const room = data.product.cpvUuid;
    socketClient.on('connect', () => {
      socketClient.emit('subscribe', {
        channel: room,
        subChannels: {
          language: data.company.language,
          deliveryPlatform: data.clientplatform || 'Web',
          playerUuid: player.id || null,
        },
      });
    });
    socketClient.on(room, (event) => {
      eventHandler.handleMessages(event);
    });
  },
  addSubChannels(channel, playerId) {
    if (socketClient) {
      socketClient.emit('addSubChannels', {
        channel,
        subChannels: {
          playerUuid: playerId,
        },
      });
    }
  },
  removeSubChannels(channel) {
    if (socketClient) {
      socketClient.emit('removeSubChannels', {
        channel,
        subChannels: ['playerUuid'],
      });
    }
  },
  balanceSubscribe(data) {
    const room = `${data.tenantId}-${data.username}`;
    socketClient.on('connect', () => {
      socketClient.emit('subscribe', {
        channel: room,
        subChannels: {},
      });
    });

    socketClient.on(room, (event) => {
      eventHandler.handleMessages(event);
    });
  },
  disconnect() {
    socketClient.disconnect();
  },
  send(message, data) {
    socketClient.emit(message, data);
  },
};
