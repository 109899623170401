export default {
  user: {
    auth: {},
    balance: 0,
    profile: {},
    bonuses: [],
  },
  isLoggedIn: false,
  isTerminalUser: false,
};
