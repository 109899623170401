import { uuid } from 'vue-uuid';
import setValue from '../../../utility/setValue';
import mutationTypes from './mutationTypes';

export default {
  [mutationTypes.ADD_BET](state, bet) {
    setValue(bet, 'id', uuid.v4());
    setValue(bet, 'isValid', true);

    if (state.config.isFuturePerTicketAllowed) {
      if (state.futureRounds !== bet.numEvents) {
        setValue(bet, 'numEvents', state.futureRounds);
      }
    } else {
      // Games that don't have future rounds
      setValue(bet, 'numEvents', 1);
    }

    state.ticket.push(bet);
  },
  [mutationTypes.ADD_PRE_BET](state, bet) {
    state.preBet = bet;
  },
  [mutationTypes.BET_PAYMENT_DELETE](state, { betId, value }) {
    const { ticket } = state;
    const target = ticket.find(bet => bet.id === betId);
    const newBet = { ...target, stake: value, isValid: true };
    const indexOfTarget = ticket.indexOf(target);

    ticket.splice(indexOfTarget, 1, newBet);
  },
  [mutationTypes.REMOVE_BETS](state) {
    const { ticket } = state;

    ticket.splice(0);
  },
  [mutationTypes.REMOVE_BET](state, id) {
    const { ticket } = state;
    const target = ticket.find(bet => bet.id === id);
    const indexOfTarget = ticket.indexOf(target);

    ticket.splice(indexOfTarget, 1);
  },
  [mutationTypes.SET_CONFIG](state, config) {
    state.config = config;
  },
  [mutationTypes.SET_ACTIVE_COMPONENT](state, component) {
    state.activeComponent = component;
  },
  [mutationTypes.SET_ACTIVE_TICKET_TYPE](state, ticketType) {
    state.activeTicketType = ticketType;
  },
  [mutationTypes.UPDATE_BET](state, newBet) {
    const { ticket } = state;
    const oldBet = ticket.find(bet => bet.id === newBet.id);
    const indexOfOldBet = ticket.indexOf(oldBet);

    ticket.splice(indexOfOldBet, 1, newBet);
  },
  [mutationTypes.UPDATE_SELECTED_BET](state, newSelectedBet) {
    state.preBet.bet = newSelectedBet;
  },
  [mutationTypes.SET_ROUND_NUMBER](state, { betId, roundNumber }) {
    const { ticket } = state;
    const targetBet = ticket.find(bet => bet.id === betId);

    setValue(targetBet, 'roundNumber', roundNumber);
  },
  [mutationTypes.SET_MIN_BET_PAYMENT](state, minBetPayment) {
    state.minBetPayment = minBetPayment;
  },
  [mutationTypes.SET_MIN_TICKET_PAYMENT](state, minTicketPayment) {
    state.minTicketPayment = minTicketPayment;
  },
  [mutationTypes.SET_TOTAL_PAYMENT](state, totalPayment) {
    state.totalPayment = totalPayment;
  },
  [mutationTypes.SET_MIN_PAYMENT_ALLOWED](state, minPaymentAllowed) {
    state.minPaymentAllowed = minPaymentAllowed;
  },
  [mutationTypes.SET_MAX_PAYMENT_ALLOWED](state, maxPaymentAllowed) {
    state.maxPaymentAllowed = maxPaymentAllowed;
  },
  [mutationTypes.SET_PAYMENT_PER_BET](state, paymentPerBet) {
    state.paymentPerBet = paymentPerBet;
  },
  [mutationTypes.SET_TICKET](state, ticket) {
    state.ticket = ticket;
  },
  [mutationTypes.SET_BET_PAYMENT](state, { betStake, betId }) {
    const { ticket } = state;
    const target = ticket.find(bet => bet.id === betId);
    const newBet = { ...target, stake: betStake };
    const indexOfTarget = ticket.indexOf(target);

    ticket.splice(indexOfTarget, 1, newBet);
  },
  [mutationTypes.SET_IS_VALID_BET](state, { betId, isValid }) {
    const { ticket } = state;
    const target = ticket.find(bet => bet.id === betId);
    const newBet = { ...target, isValid };
    const indexOfTarget = ticket.indexOf(target);

    ticket.splice(indexOfTarget, 1, newBet);
  },
  [mutationTypes.SET_FUTURE_ROUNDS](state, futureRounds) {
    state.futureRounds = futureRounds;
  },
  [mutationTypes.SET_FUTURE_ROUNDS_PER_BET](state, { futureRounds, betId }) {
    const { ticket } = state;
    const target = ticket.find(bet => bet.id === betId);
    const newBet = { ...target, numEvents: futureRounds };
    const indexOfTarget = ticket.indexOf(target);

    ticket.splice(indexOfTarget, 1, newBet);
  },
  [mutationTypes.SET_IS_MANUAL_MODE](state, isManualMode) {
    state.isManualMode = isManualMode;
  },
  [mutationTypes.SET_PLAYER_TICKETS](state, playerTickets) {
    state.playerTickets = playerTickets;
  },
  [mutationTypes.SET_POSSIBLE_WIN](state, possibleWin) {
    state.possibleWin = possibleWin;
  },
  [mutationTypes.SET_IS_PAYIN_IN_PROGRESS](state, isInProgress) {
    state.isPayinInProgress = isInProgress;
  },
  [mutationTypes.SET_IS_LAST_TICKETS_IN_PROGRESS](state, isInProgress) {
    state.isLastTicketsInProgress = isInProgress;
  },
  [mutationTypes.SET_IS_QUICKPAY_ENABLED](state, isEnabled) {
    state.isQuickpayEnabled = isEnabled;
  },
  [mutationTypes.SET_IS_PAYIN_BUTTON_DISABLED](state, isDisabled) {
    state.isPayinButtonDisabled = isDisabled;
  },
  [mutationTypes.SET_IS_PAYMENT_PER_BET_VALID](state, isValid) {
    state.isPaymentPerBetValid = isValid;
  },
  [mutationTypes.SET_POSSIBLE_WIN_PRE_BET](state, possibleWin) {
    state.possibleWinPreBet = possibleWin;
  },
  [mutationTypes.SET_TOTAL_ODDS](state, totalOdds) {
    state.totalOdds = totalOdds;
  },
  [mutationTypes.SET_POSSIBLE_WIN_COMBO](state, possibleWin) {
    state.possibleWinCombo = possibleWin;
  },
  [mutationTypes.SET_ACTIVE_BETSLIP_INPUT](state, activeBetslipInput) {
    state.activeBetslipInput = activeBetslipInput;
  },
  [mutationTypes.SET_IS_STAKE_BOX_VISIBLE](state, isStakeBoxVisible) {
    state.isStakeBoxVisible = isStakeBoxVisible;
  },
  [mutationTypes.SET_IS_BET_APPEAR_ANIMATION_DISABLED](state, isDisabled) {
    state.isBetAppearAnimationDisabled = isDisabled;
  },
  [mutationTypes.SET_IS_TOTAL_PAYMENT_VALID](state, isValid) {
    state.isTotalPaymentValid = isValid;
  },
  [mutationTypes.SET_IS_PAYMENT_VALID](state, isValid) {
    state.isPaymentValid = isValid;
  },
  [mutationTypes.SET_IS_CANT_SPLIT_ACTIVE](state, isActive) {
    state.isCantSplitActive = isActive;
  },
  [mutationTypes.SET_IS_USER_BALANCE_INVALID](state, isInvalid) {
    state.isUserBalanceInvalid = isInvalid;
  },
  [mutationTypes.SET_BETSLIP_BLOCKERS](state, payload) {
    state.betslipBlockers = payload;
  },
};
